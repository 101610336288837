export const url = "https://api.zeterads.com";
export const dev_url = "https://api.zeterads.com";
export const google_client_id =
  "753469992638-356kmtfjp638ab3mchpuqh3bopo73ml1.apps.googleusercontent.com";
export const facebook_client_id = "";
// check if it is running locally or on server
export const redirect_uri = window.location.href.includes("127.0.0.1")
  ? "http://127.0.0.1:3000/dashboard/youtube_auth"
  : "https://zeterads.com/dashboard/youtube_auth";
  
export const google_oauth_link = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${redirect_uri}&prompt=consent&response_type=code&client_id=907573268534-hfhdfd9to3e3uaq8kp4qc9a65erov4er.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics-monetary.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.channel-memberships.creator%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.force-ssl%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.upload%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner-channel-audit&access_type=offline&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow`;
//export const google_oauth_link = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${redirect_uri}&prompt=consent&response_type=code&client_id=753469992638-356kmtfjp638ab3mchpuqh3bopo73ml1.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics-monetary.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.channel-memberships.creator%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.force-ssl%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.upload%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner-channel-audit&access_type=offline&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow`;

export const google_oauth_link_2 = `https://accounts.google.com/o/oauth2/v2/auth?
scope=https%3A//www.googleapis.com/auth/drive.metadata.readonly&
include_granted_scopes=true&
response_type=token&
state=state_parameter_passthrough_value&
redirect_uri=http://localhost:3000&
grant_type=refresh_token& 
client_id=753469992638-356kmtfjp638ab3mchpuqh3bopo73ml1.apps.googleusercontent.com`;

export const auth_2Google = `https://oauth2.googleapis.com/token?
code=AUTHORIZATION_CODE_HERE
&client_id=753469992638-356kmtfjp638ab3mchpuqh3bopo73ml1.apps.googleusercontent.com
&client_secret=YOUR_CLIENT_SECRET
&redirect_uri=https://zeter-web-tewedaj.vercel.app
&grant_type=authorization_code`;

export const facebook_oauth_link = `https://www.facebook.com/v18.0/dialog/oauth?
client_id=1374595126799639
&display=popup
    &response_type=token
&redirect_uri=https://zeter-web-tewedaj.vercel.app/facebook/callback
&state=your_state_value`;

// https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https://zeter-web-tewedaj.vercel.app&prompt=consent&response_type=code&client_id=753469992638-356kmtfjp638ab3mchpuqh3bopo73ml1.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics-monetary.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.channel-memberships.creator%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.force-ssl%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.upload%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutubepartner-channel-audit&access_type=offline&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow

// https://zeter-web-tewedaj.vercel.app/?code=4/0AfJohXnCmcMnMywVeM1IjtSUtZRDgfhGEM_ZHM06ek72Ae-TgnPU80Ix3QxKGdS2Sj4C3Q&scope=https://www.googleapis.com/auth/youtube.channel-memberships.creator%20https://www.googleapis.com/auth/youtube.force-ssl%20https://www.googleapis.com/auth/youtubepartner-channel-audit%20https://www.googleapis.com/auth/yt-analytics-monetary.readonly%20https://www.googleapis.com/auth/youtube.upload%20https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/youtubepartner%20https://www.googleapis.com/auth/yt-analytics.readonly%20https://www.googleapis.com/auth/youtube
