import { useEffect, useState } from "react";
import { formBuilder } from "../../../../component/forms/prop.form";
import CForm from "../../../../component/forms/form";
import { Button } from "@mui/material";
import axios from "axios";
import { url } from "../../../../util/constant";
import CFormComponent from "../../../../component/forms/formComponent";


export default function ManualConnect({setTelegramInfo}:any) {

    const [formData,setFormData] = useState<formBuilder[]>([
        {
          label: "Channel Link",
          name: "channelLink",
          value: "",
          type: "TextInput",
          placeholder: "t.me/zeterads",
          checkListValues: [],
          onChange: () => { },
          required: true,
        },
        {
          label: "Chat Title",
          name: "chat_title",
          value: "",
          type: "TextInput",
          placeholder: "zeterads",
          checkListValues: [],
          onChange: () => { },
          required: true,
        },
          {
            label: "Niche",
            name: "niche",
            value: "",
            type: "dropdown",
            placeholder: "Select Niche",
            checkList: ["Entertainment","News","Education"],
            checkListValues: [],
            onChange: () => { },
            required: true,
          },
          // {
          //   label: "Language",
          //   name: "Language",
          //   value: "",
          //   type: "dropdown",
          //   placeholder: "Select Language",
          //   checkList: ["Amharic","Oromifa","Tigrayian"],
          //   checkListValues: [],
          //   onChange: () => { },
          //   required: true,
          // },
        
      ]);

      useEffect(()=>{
                // creator/get_all_niches
               
                axios.get(url + "/creator/get_all_niches").then((res)=>{
                    let data = res.data;
                    let checkList = data.map((item:any)=>item.name);

                    let checkListValues = data.map((item:any)=>item.id);
                    

                    let updatedForm = formData.map((item)=>{
                        if(item.name === "niche"){
                            return {...item,checkList:checkList,checkListValues:checkListValues}
                        }
                        return item;
                    })
                    console.log("FINAL: " ,updatedForm);
                    setFormData(updatedForm); 
                    setUpdated(true);
                }
                ).catch((err)=>{
                    console.log(err);
                })
      },[])


      // const [telegramInfo,setTelegramInfo] = useState<any>();
      const [updated,setUpdated] = useState<boolean>(false);

    return(
        <div style={{width: '50%'}}>
            
            {updated && 
              <CFormComponent formData={formData} callback={(updatedDate: formBuilder[])=>{
                setTelegramInfo(updatedDate);
              }} startForm={0} endForm={11} />
          }

        </div>
    )
}




