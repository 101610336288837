export default function IncomeReport() {


    const incomes = [
        {
            title: "Weekly Income",
            business: 3000,
            creator: 2000,
            total: 5000
        },
        {
            title: "Montly Income",
            business: 12000,
            creator: 8000,
            total: 20000
        },
        {
            title: "Quarterly Income",
            business: 25000,
            creator: 24000,
            total: 49000
        },
        {
            title: "Yearly Income",
            business: 120000,
            creator: 100000,
            total: 220000
        },
    ]


    return (

<>

        <div style={{display:"flex", padding:"3%" }}>


            <div className="flex h-full flex-col justify-center m-[1%]">

                <div className="mx-auto w-full max-w-2xl rounded-sm border border-gray-200 bg-white shadow-lg">
                    <header className="border-b border-gray-100 px-5 py-4">
                        <div className="font-semibold text-gray-800">Weekly Income</div>
                    </header>

                    <div className="overflow-x-auto p-3">
                        <table className="w-full table-auto">
                            <thead className="bg-gray-50 text-xs font-semibold uppercase text-gray-400">
                                <tr>
                                    <th></th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Name</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total Business/Creator</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total</div>
                                    </th>

                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-100 text-sm">

                                <tr>
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-1" />
                                    </td>
                                    <td className="p-2 text-left">
                                        <div className="font-medium text-gray-800">Business</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">10</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">5000</div>
                                    </td>

                                </tr>


                                <tr >
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-2" />
                                    </td>
                                    <td className="p-2 text-left" >
                                        <div>
                                            <div className="font-medium text-gray-800">Creators</div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">30</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">7000</div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="flex justify-end space-x-4 border-t border-gray-100 px-5 py-4 text-2xl font-bold">
                        <div>Total</div>
                        <div className="text-blue-600">12000 <span x-text="total.toFixed(2)"></span></div>
                    </div>

                    <div className="flex justify-end">

                        <input type="hidden" className="border border-black bg-gray-50" x-model="selected" />
                    </div>
                </div>
            </div>

            <div className="flex h-full flex-col justify-center m-[1%]">

                <div className="mx-auto w-full max-w-2xl rounded-sm border border-gray-200 bg-white shadow-lg">
                    <header className="border-b border-gray-100 px-5 py-4">
                        <div className="font-semibold text-gray-800">Monthly Income</div>
                    </header>

                    <div className="overflow-x-auto p-3">
                        <table className="w-full table-auto">
                            <thead className="bg-gray-50 text-xs font-semibold uppercase text-gray-400">
                                <tr>
                                    <th></th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Name</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total Business/Creator</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total</div>
                                    </th>

                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-100 text-sm">

                                <tr>
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-1" />
                                    </td>
                                    <td className="p-2 text-left">
                                        <div className="font-medium text-gray-800">Business</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">10</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">5000</div>
                                    </td>

                                </tr>


                                <tr >
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-2" />
                                    </td>
                                    <td className="p-2 text-left" >
                                        <div>
                                            <div className="font-medium text-gray-800">Creators</div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">30</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">7000</div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="flex justify-end space-x-4 border-t border-gray-100 px-5 py-4 text-2xl font-bold">
                        <div>Total</div>
                        <div className="text-blue-600">12000 <span x-text="total.toFixed(2)"></span></div>
                    </div>

                    <div className="flex justify-end">

                        <input type="hidden" className="border border-black bg-gray-50" x-model="selected" />
                    </div>
                </div>
            </div>
            <div className="flex h-full flex-col justify-center m-[1%]">

                <div className="mx-auto w-full max-w-2xl rounded-sm border border-gray-200 bg-white shadow-lg">
                    <header className="border-b border-gray-100 px-5 py-4">
                        <div className="font-semibold text-gray-800">Quarterly Income</div>
                    </header>

                    <div className="overflow-x-auto p-3">
                        <table className="w-full table-auto">
                            <thead className="bg-gray-50 text-xs font-semibold uppercase text-gray-400">
                                <tr>
                                    <th></th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Name</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total Business/Creator</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total</div>
                                    </th>

                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-100 text-sm">

                                <tr>
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-1" />
                                    </td>
                                    <td className="p-2 text-left">
                                        <div className="font-medium text-gray-800">Business</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">10</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">5000</div>
                                    </td>

                                </tr>


                                <tr >
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-2" />
                                    </td>
                                    <td className="p-2 text-left" >
                                        <div>
                                            <div className="font-medium text-gray-800">Creators</div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">30</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">7000</div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="flex justify-end space-x-4 border-t border-gray-100 px-5 py-4 text-2xl font-bold">
                        <div>Total</div>
                        <div className="text-blue-600">12000 <span x-text="total.toFixed(2)"></span></div>
                    </div>

                    <div className="flex justify-end">

                        <input type="hidden" className="border border-black bg-gray-50" x-model="selected" />
                    </div>
                </div>
            </div>
            <div className="flex h-full flex-col justify-center m-[1%]">

                <div className="mx-auto w-full max-w-2xl rounded-sm border border-gray-200 bg-white shadow-lg">
                    <header className="border-b border-gray-100 px-5 py-4">
                        <div className="font-semibold text-gray-800">Yearly Income</div>
                    </header>

                    <div className="overflow-x-auto p-3">
                        <table className="w-full table-auto">
                            <thead className="bg-gray-50 text-xs font-semibold uppercase text-gray-400">
                                <tr>
                                    <th></th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Name</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total Business/Creator</div>
                                    </th>
                                    <th className="p-2">
                                        <div className="text-left font-semibold">Total</div>
                                    </th>

                                </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-100 text-sm">

                                <tr>
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-1" />
                                    </td>
                                    <td className="p-2 text-left">
                                        <div className="font-medium text-gray-800">Business</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">10</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">5000</div>
                                    </td>

                                </tr>


                                <tr >
                                    <td className="p-2">
                                        <input type="checkbox" className="h-5 w-5" value="id-2" />
                                    </td>
                                    <td className="p-2 text-left" >
                                        <div>
                                            <div className="font-medium text-gray-800">Creators</div>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left">30</div>
                                    </td>
                                    <td className="p-2">
                                        <div className="text-left font-medium text-green-500">7000</div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="flex justify-end space-x-4 border-t border-gray-100 px-5 py-4 text-2xl font-bold">
                        <div>Total</div>
                        <div className="text-blue-600">12000 <span x-text="total.toFixed(2)"></span></div>
                    </div>

                    <div className="flex justify-end">

                        <input type="hidden" className="border border-black bg-gray-50" x-model="selected" />
                    </div>
                </div>
            </div>






        </div>
         <div className="flex h-full flex-col justify-center m-[1%]">

         <div className="mx-auto w-full max-w-2xl rounded-sm border border-gray-200 bg-white shadow-lg">
             <header className="border-b border-gray-100 px-5 py-4">
                 <div className="font-semibold text-gray-800">Next Month Income Prediction</div>
             </header>

             <div className="overflow-x-auto p-3">
                 <table className="w-full table-auto">
                     <thead className="bg-gray-50 text-xs font-semibold uppercase text-gray-400">
                         <tr>
                             <th></th>
                             <th className="p-2">
                                 <div className="text-left font-semibold">Name</div>
                             </th>
                             <th className="p-2">
                                 <div className="text-left font-semibold">Total Business/Creator</div>
                             </th>
                             <th className="p-2">
                                 <div className="text-left font-semibold">Total</div>
                             </th>

                         </tr>
                     </thead>

                     <tbody className="divide-y divide-gray-100 text-sm">

                         <tr>
                             <td className="p-2">
                                 <input type="checkbox" className="h-5 w-5" value="id-1" />
                             </td>
                             <td className="p-2 text-left">
                                 <div className="font-medium text-gray-800">Business</div>
                             </td>
                             <td className="p-2">
                                 <div className="text-left">10</div>
                             </td>
                             <td className="p-2">
                                 <div className="text-left font-medium text-green-500">5000</div>
                             </td>

                         </tr>


                         <tr >
                             <td className="p-2">
                                 <input type="checkbox" className="h-5 w-5" value="id-2" />
                             </td>
                             <td className="p-2 text-left" >
                                 <div>
                                     <div className="font-medium text-gray-800">Creators</div>
                                 </div>
                             </td>
                             <td className="p-2">
                                 <div className="text-left">30</div>
                             </td>
                             <td className="p-2">
                                 <div className="text-left font-medium text-green-500">7000</div>
                             </td>

                         </tr>
                     </tbody>
                 </table>
             </div>


             <div className="flex justify-end space-x-4 border-t border-gray-100 px-5 py-4 text-2xl font-bold">
                 <div>Total</div>
                 <div className="text-blue-600">12000 <span x-text="total.toFixed(2)"></span></div>
             </div>

             <div className="flex justify-end">

                 <input type="hidden" className="border border-black bg-gray-50" x-model="selected" />
             </div>
         </div>
     </div>

     </>


    )
}