import "./style.updateProfile.css";
import CForm from "../../../component/forms/form";
import { formBuilder } from "../../../component/forms/prop.form";
import { useState } from "react";
import { getJson } from "../../../component/forms/controller.form";
import {
  buildCreatorApiBody,
  createAccountApiCall,
} from "../../create_account/controller.create_account";
import { Loading } from "../../../component/loading/loading";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorToast from "../../../component/toast/error";
import { Button } from "@mui/material";
export default function UpdateProfile({closeComponent}: any) {
  const [formData, setFormData] = useState<formBuilder[]>([
    {
      label: "First Name",
      name: "firstName",
      value: "",
      type: "TextInput",
      placeholder: "First Name",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      value: "",
      type: "TextInput",
      placeholder: "Last Name",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "User Name",
      name: "userName",
      value: "",
      type: "TextInput",
      placeholder: "User Name",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Password",
      name: "password",
      value: "",
      type: "TextInput",
      placeholder: "Password",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Date of Birth",
      name: "dob",
      value: "",
      type: "date",
      placeholder: "Date of Birth",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Gender",
      name: "gender",
      value: "",
      type: "dropdown",
      placeholder: "Gender",
      checkListValues: [],
      checkList: ["", "male", "female"],
      onChange: () => {},
      required: true,
    },
    {
      label: "Email",
      name: "email",
      value: "",
      type: "TextInput",
      placeholder: "Email",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      value: "",
      type: "TextInput",
      placeholder: "Phone Number",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Woreda",
      name: "woreda",
      value: "",
      type: "TextInput",
      placeholder: "Woreda",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Sub City",
      name: "subCity",
      value: "",
      type: "TextInput",
      placeholder: "Sub City",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "City",
      name: "city",
      value: "",
      type: "TextInput",
      placeholder: "City",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Active",
      name: "isActive",
      value: "",
      type: "TextInput",
      placeholder: "Active",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Region",
      name: "region",
      value: "",
      type: "TextInput",
      placeholder: "Region",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Identification Image",
      name: "identificationImage",
      value: "",
      type: "file",
      placeholder: "Identification Image",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
  ]);
  const [showErrorMessage,setShowErrorMessage] = useState(false);

  const handleClose = () => {
    setShowErrorMessage(false);
    // Additional logic for closing the component
  };

  const navigate = useNavigate();

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [errorMsg, setErrorMsg] = useState("");
  const [updatedContent, setUpdatedContent] = useState<formBuilder[]>();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div   style={{
      position: 'fixed',
      top: 0,
      left: 0,
      background: 'rgba(0, 136, 204, 0.19)',
      width: '100%',
      height: '100%',
      zIndex: 10000,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  }}> 
    <div className="updateAccount">
      <Loading loading={loading} setLoading={"sef"} />
      {showErrorMessage && <ErrorToast message="Some thing went wrong, please check you connnection and try again!" onClose={handleClose}/> }
      <div className="updateContent">
       

        <div className={"formUpdate"}>
          <Button onClick={()=>{
            closeComponent();
          }}>
            Close
          </Button>
        <div className="updateTitle">
         Update Profile
        </div>
        {errorMsg === "" ? (
          <></>
        ) : (
          <div className="errorContainer">
            <p className="font-bold text-lg">{errorMsg.toString().toLowerCase()}</p>

            <p className="font-bold">are required</p>
          </div>
        )}
          {CForm(
            formData,
            (updatedDate: formBuilder[]) => {
              setUpdatedContent(updatedDate);
            },
            start,
            end
          )}
              <div className={"actions"}>
          {start === 0 ? (
            <></>
          ) : (
            <button
              onClick={() => {
                console.log("start: ", end - start - 1, "end: ", start - 1);
                setStart(start - 4);
                setEnd(end - 4);
              }}
              className="bg-red-400"
            >
              Back
            </button>
          )}

          <button
            onClick={async () => {
              if (end <= formData.length) {
                var inputs = getJson(updatedContent || formData);
                const valuesArray = Object.values(inputs);
                const keysArray = Object.keys(inputs);

                const extractedValues = valuesArray.slice(start, end + 1);
                const extractedKeys = keysArray.slice(start, end + 1);
                const emptyStringIndexes: any = [];
                extractedValues.forEach((value, index) => {
                  if (value === "") {
                    emptyStringIndexes.push(index);
                  }
                });

                console.log("empty sting values: ", extractedValues);

                if (emptyStringIndexes.length === 0) {
                  setErrorMsg("");
                  setStart(start + 4);

                  setEnd(end + 4);
                } else {
                  var errorsMessage: string = "";
                  emptyStringIndexes.forEach((value: any, index: number) => {
                    errorsMessage += extractedKeys[value] + " ";
                  });
                  setErrorMsg(errorsMessage);
                }
              } else {
                var newJson = getJson(updatedContent || formData);
                var content = buildCreatorApiBody(newJson);
                setLoading(true);
                var response: any = await createAccountApiCall(content);
                setTimeout(() => {
                  setLoading(false);
                  if (response.status === 200 || response.status === 201) {
                    navigate("/creator_login");
                  } else {
                    setLoading(false);
                    setShowErrorMessage(true);
                  }
                }, 4000);
              }
            }}
            className={"roundButton bg-green-400"}
          >
            {end <= formData.length ? "Next" : "Submit"}
          </button>
        </div>
        </div>
    
      </div>
      {/* <div className="loginDecore">
        <img src={tools} alt="tools" />
      </div> */}
    </div>
    </div>
  );
}
