// SocialMediaSelection.tsx
import React from 'react';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaTelegramPlane, FaTiktok } from 'react-icons/fa';
import { handleSocialMediaClick } from '../../../../util/campaign/createCampaign';

interface Props {
    socialMedia: string;
    setSocialMedia: React.Dispatch<React.SetStateAction<string>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const SocialMediaSelection: React.FC<Props> = ({ socialMedia, setSocialMedia, setPages }) => {
    return (
        <div className="fadeIn">
            <p className='question'> Select Social Media</p>
            <div className='youTubeTelegram'>
                <button className={socialMedia === "youtube" ? "youTubeButton youTubeButtonClicked" : "youTubeButton"} onClick={() => handleSocialMediaClick("youtube", setSocialMedia, setPages)} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IoLogoYoutube size={50} style={{ marginRight: "5px" }} />
                    </div>
                    <div>YouTube</div>
                </button>
                <button className={socialMedia === "telegram" ? "telegramButton telegramButtonClicked" : "telegramButton"} onClick={() => handleSocialMediaClick("telegram", setSocialMedia, setPages)} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FaTelegramPlane size={50} style={{ marginRight: "5px" }} />
                    </div>
                    <div>Telegram</div>
                </button>


            </div>

            <button className="tikTokButton" onClick={() => handleSocialMediaClick("tiktok", setSocialMedia, setPages)} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FaTiktok size={50} style={{ marginRight: "5px" }} />
                </div>
                <div>TikTok</div>
            </button>
        </div>

    );
};

export default SocialMediaSelection;
