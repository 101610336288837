import React from 'react';
import { handleMegaNich } from '../../../../util/campaign/createCampaign';

interface Props {
    niche: string;
    setMegaNich: React.Dispatch<React.SetStateAction<string>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const MegaNichSelection: React.FC<Props> = ({ niche, setMegaNich, setPages }) => {
    return (
        <div className="fadeIn">
        <p className='question'> Select Niche</p>
        <div className='youTubeTelegram'>
          <button className={niche === "entertainment" ? "entertainmentButton entertainmentButtonClicked" : "entertainmentButton"} onClick={() => handleMegaNich("entertainment", setMegaNich, setPages)}>Entertainment</button>
          <button className={niche === "education" ? "educationButton educationButtonClicked" : "educationButton"} onClick={() => handleMegaNich("education", setMegaNich, setPages)}>Education</button>
        </div>
        <button className={niche === "news" ? "premiumButton premiumButtonClicked" : "premiumButton"} onClick={() => handleMegaNich("news", setMegaNich, setPages)}>News
        </button>
      </div>
    );
};

export default MegaNichSelection;
