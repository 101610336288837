import { formBuilder } from "./prop.form";

export function buildForm(){
    
}

export function getJson(formSchema: formBuilder[]){
    var newJson : any = {};
    formSchema.forEach((element) => {
        if(element.name){
            if(element.type === "checkbox" ){

                var values: string[] = [];
                element.checkListValues.forEach((value, index) => {
                    if(value){
                        values.push(element.checkListData![index]);
                    }
                });
                newJson[element!.name] = values;
            }else if(element.type === "dropdown"){
                let indexWanted = 0;
                let cc = element as any;
                cc.checkList.forEach((value: any, index: number) => {
                    if(value == element.value){
                        indexWanted = index;
                    }
                });
                newJson[element!.name] = element.checkListValues[indexWanted];

            } else{
                
                newJson[element!.name] = element.value;
            }
    }
    });

    return newJson;
}