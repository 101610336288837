import { useEffect, useState } from "react";
import "./VideoUploader.style.css";
import { videoChecker, videoUpload } from "./videoUploader.controller";
import { Loading } from "../../../../component/loading/loading";
import { Hidden, dividerClasses } from "@mui/material";
import ShortenText from "../../../../component/shortenText/ShortenText";
import OrderStatus from "../order.status";
import ChatBox from "../../../../component/chat_box/chatBox";
import { AiOutlineCopy } from "react-icons/ai";

export default function VideoUploader(prop: any) {
  const [entredUrl, setEnteredUrl] = useState("");
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [userInfo,setUserInfo] = useState<any>({});
  const [campaignId,setCampaignId] = useState<number>(0);
  const [creatorInA_campaign_id,setCreatorInA_campaign_id] = useState<number>(0);
  const [zeterVideoCode, setZeterVideoCode] = useState<string>("");

  useEffect(()=>{
    console.log("prop: ",  prop.campaignData);
    // get user id from local storage
    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    console.log("userInfo: ", userInfo);
    setZeterVideoCode("ZE"+prop.campaignData.campaignId+"TE"+prop.campaignData.id);
    setCampaignId(prop.campaignData.campaignId);
    setCreatorInA_campaign_id(prop.campaignData.id);
    setUserInfo(userInfo);
  },[])

  return (

    <div className="videoUploaderScreen">
      <div style={{width: '85%'}}>
         <OrderStatus status={"production"} />
      <span style={{height: 150}}>
        
      </span>
      </div>
      {
        prop.campaignData.campaign.socialMediaId == 1 ? 
              <div className="videoUploaderFormNew">
                  <h1>{"Zeter Code:   "+ zeterVideoCode}

                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(zeterVideoCode);
                    }}
                  style={{margin:10}}>
                      <AiOutlineCopy />
                    </button>
                  </h1>
                  <p style={{color:'gray'}}>
                    Please make sure to add your zeter code on your videos description, we recomand to put it at the last part of your description to avoid it bing on top
                    </p>
                <input
                  style={{ border: "1px solid gray" }}
                  onChange={(e) => {
                    setEnteredUrl(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter Video Link"
                />
                <div className="buttons">


                  <button
                    onClick={() => {
                      setLoading(true);
                      videoChecker(entredUrl,creatorInA_campaign_id)
                        .then((res) => {
                    
                          console.log("res: ", res);
                          setVideoDetails(res);
                        
                          setLoading(false);
                        })
                        .catch((err) => {
                          console.log("ERRR: ", err);
                        });
                    }}
                    className="roundButtonBorder"
                  >
                    {"Check"}
                  </button>
                </div>

                {check || videoDetails.passed && <>
                <div style={{ display: "flex", flexDirection: "row", flexWrap:'wrap', justifyContent: "space-between", marginTop: "3%", width:"100%" }}>


                  <div className="thumbnail">
                    <img
                      src={videoDetails?.thumbnail || "klj"}
                    
                    />
                  </div>


                  <div
                    className="videoInfo"
                  >
                    <div className="innerDetail">
                      {/* <p>{"Video Title: "}</p> */}
                      <p> {videoDetails?.title || "NA"} </p>
                    </div>

                    <div className="innerDetail">
                      {/* <p>{"Video Description: "}</p> */}
                      {/* <p>  </p> */}
                      <ShortenText text={videoDetails?.description ? videoDetails?.description : "NA"} maxLength={300} />
                    </div>

                    <div className="innerDetail">
                      {/* <p>{"Video View: "}</p> */}
                      <p> {videoDetails?.View || "NA"} </p>
                    </div>
                  </div>

                </div>

                <div className="buttons">
                  <button
                    onClick={() => {
                      setLoading(true);

                      console.log("HKK: ", prop.campaignData);
                      videoUpload(prop.campaignData.campaignId, entredUrl, prop.campaignData.id,prop.campaignData.socialMediaAccountId,1)
                        .then((res) => {
                          setLoading(false);
                          prop.acceptStatus();
                        })
                        .catch((err) => { });
                    }}
                    className="roundButtonBorder"
                  >
                    {"Submit"}
                  </button>

                </div></>}



                  


              </div>

                      :

                      <div className="videoUploaderFormNew">
                         <button
                          onClick={() => {
                            videoUpload(prop.campaignData.campaignId, "url", prop.campaignData.id,prop.campaignData.socialMediaAccountId,3)
                            .then((res) => {
                              setLoading(false);
                              prop.acceptStatus();
                            })
                            .catch((err) => { });
                          }}
                         > <h1> Upload AD </h1></button>
                        </div>
      }
      {campaignId && userInfo?.body?.id &&
      <ChatBox campaignId={campaignId} userId={userInfo?.body?.id} />
      
      }

    </div>





    // <div
    //   className="videoUploaderScreen"
    //   style={{ backgroundImage: `url(${prop?.campaignData?.thumbnail || ""})` }}
    // >
    //   {/* <h1>Video Uploader</h1> */}
    //   <div className="videoUploaderForm">
    //     <input
    //       onChange={(e) => {
    //         setEnteredUrl(e.target.value);
    //       }}
    //       type="text"
    //       placeholder="Enter Video Link"
    //     />
    //     <div style={{ display: "flex", flexDirection: "row" }}>
    //       <div style={{ width: 300, height: 200, overflow: "hidden" }}>
    //         <img
    //           src={videoDetails?.thumbnail || "klj"}
    //           width="400px"
    //           height="100px"
    //         />
    //       </div>
    //       <div
    //         style={{ display: "flex", flexDirection: "column", width: "65%" }}
    //       >
    //         <div className="innerDetail">
    //           <p>{"Video Title: "}</p>
    //           <p> {videoDetails?.title || "NA"} </p>
    //         </div>

    //         <div className="innerDetail">
    //           <p>{"Video Description: "}</p>
    //           <p> {videoDetails?.description} </p>
    //         </div>

    //         <div className="innerDetail">
    //           <p>{"Video View: "}</p>
    //           <p> {videoDetails?.View || "NA"} </p>
    //         </div>
    //         <div className="actionButtons">
    //           <button
    //             onClick={() => {
    //               setLoading(true);
    //               videoChecker(entredUrl)
    //                 .then((res) => {
    //                   console.log("res: ", res);
    //                   setVideoDetails(res);
    //                   setLoading(false);
    //                 })
    //                 .catch((err) => {
    //                   console.log("ERRR: ", err);
    //                 });
    //             }}
    //             className="roundButtonBorder"
    //           >
    //             {"Check"}
    //           </button>
    //           <button
    //             onClick={() => {
    //               setLoading(true);

    //               console.log("HKK: ", prop.campaignData);
    //               videoUpload(prop.campaignData.CampaignId, entredUrl)
    //                 .then((res) => {
    //                   setLoading(false);
    //                   prop.acceptStatus();
    //                 })
    //                 .catch((err) => {});
    //             }}
    //             className="roundButtonBorder"
    //           >
    //             {"Submit"}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Loading
    //     loading={loading}
    //     setLoading={() => {
    //       setLoading(false);
    //     }}
    //   />
    // </div>
  );
}
