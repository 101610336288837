import { popUpProp } from "./prop.popup";
import "../loading/style.loading.css";
import "../loading/style.mobile.loading.css";
import "./style.componentPopup.css"
import { BsX } from 'react-icons/bs';

export function ComponentPopUp(popUpProp: popUpProp) {
  return (
    <div className={popUpProp.isOpen ? 'LoadingParent-active' : 'LoadingParent'}>
      <div className="bodyComponent">
      {/* <div className="bodyComponent" style={{height:'70%', width:'60%', display:'flex', flexDirection:'column'}}> */}
        <div className="popupHeader" style={{flex:0.1}}>
          <span className="closeIcon" style={{alignSelf:'flex-end', justifyContent:'flex-end', }} onClick={popUpProp.onClose}>
            <BsX />
          </span>
        </div>

        {popUpProp.customComponent && (
          <div className="customComponentContainer" style={{background:'white', flex:1 , width:'100%'}}>
            {/* Render the custom component */}
            {popUpProp.customComponent}
          </div>
        )}
      </div>
    </div>
  );
}
