

interface ProgressBarProps {
    progress: number;
    progressBarWidth: number;
    progressBarHeight: number;
}

export default function ProgressBar({progress, progressBarWidth,progressBarHeight}: ProgressBarProps){
    
 const   calculatePercentageFromWidth = (progressBarWidth: number,progress: number) => {
        return (progressBarWidth * progress) / 100;
    }

    return (
        <div style={{minWidth: progressBarWidth,height:progressBarHeight, border: '1px solid #090d25',borderRadius: 5, overflow: 'hidden',alignSelf:'center',backgroundColor:'white' }} >
          
            {progress == 0 ? 
            <div style={{height: progressBarHeight, width: progressBarWidth,background:'white',fontWeight:'bolder', color:'#090d25',display: 'flex', flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'white' }}>
                {"0%"}
            </div>
            :
            <div style={{height: progressBarHeight, width: calculatePercentageFromWidth(progressBarWidth,progress),background:'#090d25',color:'white',display: 'flex', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
            {progress+"%"}
        </div>
            }
        </div>
    );
}