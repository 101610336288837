import { dropDownProp } from "./prop.dropDown";

import "./style.dropdown.css";

export default function DropDown(dropDownProp: dropDownProp) {
  return (
    <div className="selectUI">
      <label>{dropDownProp.title}</label>

      <select
        className="w-full h-10 rounded-md border-0 bg-transparent ring-1 ring-gray-300 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
        value={dropDownProp.value}
        onChange={(e) => {
          dropDownProp.callback(e.target.selectedIndex);
        }}
      >
        {dropDownProp.list.map((res, index) => (
          <option>{res}</option>
        ))}
      </select>
    </div>
  );
}
