import React, { useState } from "react";
import "./style.menu.css";
import { menuInfo } from "./prop.menu";
import { AiFillForward } from "react-icons/ai";
import { Link } from "react-router-dom";
import { getLinkClass } from "../header/controller.header";
const Menu = (isOpenProp: menuInfo) => {
  //const [isOpen, setIsOpen] = useState(isOpenProp.isOpen);

  const toggleMenu = () => {
    isOpenProp.callBack();
  };

  return (
    <div className={`menu ${isOpenProp.isOpen ? "open" : ""}`}>
      <button className="toggle-button" onClick={toggleMenu}>
        <AiFillForward />
      </button>
      <ul className="menu-items">
        <li>
          {" "}
          <Link
            className={getLinkClass(isOpenProp.title, "home", "smallScreen")}
            to="/"
          >
            {"Home"}
          </Link>
        </li>
        <li>
          <Link
            className={getLinkClass(isOpenProp.title, "pricing", "smallScreen")}
            to="/pricing"
          >
            {"Plans"}
          </Link>
        </li>
        <li>
          <Link
            className={getLinkClass(
              isOpenProp.title,
              "how_it_works",
              "smallScreen"
            )}
            to="/how_it_works"
          >
            How it works
          </Link>
        </li>
        <li>
          <Link
            className={getLinkClass(isOpenProp.title, "about_us", "smallScreen")}
            to="/about_us"
          >
            {"About Us"}
          </Link>
        </li>
        <li>
          <Link to={"/creator_login"} className={"roundButtonBorder"}>
            Login
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
