import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CreatorData, getCreatorData } from "../../../admin_dashboard/creators/controller.creators";




interface SearchCreatorsProps {
  setSelectedCreatorList: Dispatch<SetStateAction<any[]>>;
  socialMedia: number;
}

export function SearchCreators({ setSelectedCreatorList,socialMedia }: SearchCreatorsProps) {
 
    const [creatorName,setCreatorName] = useState<string>("");
    const [search,setSearch] = useState<boolean>(false);
    const [creator,setCreator] = useState<CreatorData[]>([]);
    

    // useEffect(() => {
    //     getCreatorData(0, 10, creatorName).then((data: CreatorData[]) => {
            
    //       setCreator(data);
    //       // setSearch(false)
    
    //     }).catch((err) => {

    //     })
    
    //   }, [search])
    return(
        <div>
        <div className="flex">
        <div className="bg-white pt-4 pb-4 w-96 rounded-md">
          <h1 className="text-lg font-bold text-gray-500 text-left">Search creator</h1>
          <div className="mt-5 mb-2 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
            <input value={creatorName} onChange={(e) => {
                setCreatorName(e.target.value)
                
                }} className="flex-grow outline-none text-gray-600 focus:text-blue-600" type="text" placeholder="Search creator..." />
            <span onClick={() =>{ 
                 getCreatorData(0, 10, creatorName,socialMedia).then((data: CreatorData[]) => {
                    
                    setCreator(data);
                    // setSearch(false)
              
                  }).catch((err) => {
          
                  })

            }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </span>
          </div>
        </div>
      </div>
                <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Name
                    </th>
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Social Media Account
                    </th>
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Social Media
                    </th>
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Status
                    </th>
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Rate
                    </th>
                  
                    <th
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
    
                    </th>
                  </tr>
                </thead>
                <tbody>
    
                  {creator.length > 0  && creator?.map((creator: any) => (
                    <tr className="text-left">
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-full h-full rounded-full"
    
                              src={creator.identificationImage}
                              alt="" />
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {creator.FirstName + " " + creator.LastName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{creator.AccountName}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{creator.socialMedia}</p>
                      </td>
    
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <span
                          className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                          <span aria-hidden
                            className="absolute inset-0 bg-green-200 opacity-50 rounded-full" style={{background: creator.status == "true" ? "green" : "red" }}></span>
                          <span className="relative">{creator.status == "true" ? "Active" : "Inactive"}</span>
                        </span>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{creator.rate +" Birr / 1000 View" }</p>
                      </td>
                    
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <button 
                        onClick={()=>{ 
                          if(creator.status == "false" || creator.status == null){
                            alert("Creator is inactive")
                          }else{

                            setSelectedCreatorList([creator])
                          }
                        }}
                        className="bg-indigo-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">request</button>
                      </td>
                    </tr>))
    
                  }
    
    
    
    
                </tbody>
              </table>
              </div>
    )
}