import { contentInfo } from "./prop.content_card";
import "./style.content_card.css";
import "../global/style.css";
export default function contentCard(contentInfo: contentInfo) {
  return (
    <iframe
      // width="560"
      // height="315"
      width={"100%"}
      height={"100%"}
      src={contentInfo.url? contentInfo.url.replace("http://","https://").replace("https://www.youtube.com/watch?v=","https://www.youtube.com/embed/")  : "" }
      title={contentInfo.title}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>

    // <div className={"parentContent"}>

    //   <h3>{contentInfo.description}</h3>

    //   <button className={"primeryButton"}>Watch Now</button>
    // </div>
  );
}
