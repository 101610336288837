import { useEffect, useState } from "react";
import CForm from "../../../component/forms/form";
import { formBuilder } from "../../../component/forms/prop.form";
import "./style.createCampaign.css";
import { getJson } from "../../../component/forms/controller.form";
import { PopUp } from "../../../component/popup/popup";
import { createCampaign, getCampaignBudget } from "./controller.createCampaign";
import SuccessToast from "../../../component/toast/success";
import { useNavigate } from "react-router-dom";
import { fileUpload } from "../../create_account/controller.create_account";
import { Loading } from "../../../component/loading/loading";
import { CreatorData, getCreatorData } from "../../admin_dashboard/creators/controller.creators";
export default function CreateCampaign() {
  const navigate = useNavigate();
  const [popup, setPopUp] = useState<boolean>(false);
  const [videoError, setVideoError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState<string>("");
  const [popUpMessage, setPopUpMessage] = useState<string>("");
  const [campaignBody, setCampainBody] = useState<any>();
  const [showSuccessMesage, setShowSuccessMessage] = useState(false);
  const [videoFile, setVideoFile] = useState<string>();
  const [plan, setPlan] = useState<string>("Beginner");
  const [allCreators, setAllCreators] = useState<CreatorData[]>()
  const [creator, setCreator] = useState<CreatorData[]>()
  const [creatorName, setCreatorName] = useState("")
  const [search, setSearch] = useState(false)

  const [formData, setFormData] = useState<formBuilder[]>([
    {
      label: "Content Description",
      name: "contentDescription",
      value: "",
      type: "textArea",
      placeholder: "Describe a bit about the campaign",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {

      label: "Short Video ",
      name: "shortVideoUrl",
      value: "",
      type: "file",
      required: true,
      placeholder: "https://youtube.com/this",
      checkListValues: [],
      onChange: (value) => {
        setLoading(true);
        fileUpload(value)
          .then((response: any) => {
            if (response.status === 200 || response.status === 201) {
              setVideoFile(response?.data?.file);
              setLoading(false);
            } else {


              setLoading(false);
              setPopUp(true);
              setVideoError(true);

              setPopUpTitle("Uploading video error");
              setPopUpMessage(
                "Something went wrong while uploading video, please try again!"
              );
            }
          })
          .catch((err) => { });
      },

    },
    {
      label: "Start Date",
      name: "startDate",
      value: "",
      type: "date",
      placeholder: "",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "End Date",
      name: "endDate",
      value: "",
      type: "date",
      placeholder: "",
      checkListValues: [],
      onChange: () => {},
      required: true,
    },
    {
      label: "Expected Views",
      name: "expectedViews",
      value: "",
      type: "TextInput",
      placeholder: "",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Ad Length (In Seconds)",
      name: "adLength",
      value: "",
      type: "TextInput",
      placeholder: "30",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Social Media",
      name: "socialMedia",
      value: "",
      type: "checkbox",
      placeholder: "",
      checkListValues: [],
      checkListData: ["1", "2", "3", "4"],
      checkList: ["YouTube", "Facebook", "Instagram", "TikTok"],
      onChange: () => { },
      required: true,
    },
    {
      label: "Niche / Content Type",
      name: "nish",
      value: "",
      type: "checkbox",
      placeholder: "",
      checkListValues: [],
      checkListData: ["1", "2", "3", "4", "5", "6", "7", "8" ],
      checkList: ["Entertainment, Pop Culture and Gaming ", "Education and How to Tutorials", "Sports", "Beauty and Fashion", "Health and Fitness", "Travel and Adventure", "Food and Cooking Related", "News"],
      onChange: () => { },
      required: true,
    },
    {
      label: "Audience Age",
      name: "Audience",
      value: "",
      type: "checkbox",
      placeholder: "",
      checkListValues: [],
      checkListData: ["1", "2", "3", "4"],
      checkList: ["14-25", "26-37", "37-47", "50+"],
      onChange: () => { },
      required: true,
    },
  ]);

  const [videoInfo, setVideoInfo] = useState<formBuilder[]>([
    {
      label: "Choce Video Source",
      value: "",
      placeholder: "Social Media Account Name",
      checkListValues: [],
      type: "checkbox",
      checkList: ["YouTube", "New Upload"],
      onChange: () => { },
      required: true,
    },
  ]);

  const handleChangePlan = (plan: string) => {
    setPlan(plan);
  }

  const handleClose = () => {
    setShowSuccessMessage(false);
  };

  const onCampainCreate = async () => {
    console.log("on okay cliked:");
    setLoading(true);
    setPopUp(false);
    await createCampaign(campaignBody)
      .then((res) => {
        setShowSuccessMessage(true);

        setLoading(false);
        navigate("/dashboard/business/listCampaign");
      })
      .catch((error) => {
        console.log("error: ", error);

      });
  };
  useEffect(() => {
    getCreatorData(0, 10, creatorName,3).then((data: CreatorData[]) => {

      setCreator(data);
      // setSearch(false)


    });

  }, [search === true])

  return (
    <div className="createCampagin">
      {showSuccessMesage && (
        <SuccessToast
          message="Campaign Created Successfully!"
          onClose={handleClose}
        />
      )}
      <PopUp
        isOpen={popup}
        title={popUpTitle}
        message={popUpMessage}
        closeButton={videoError}
        onAccept={() => onCampainCreate()}
        onClose={function (): void {
          setPopUp(false);
          setVideoError(false)
        }}
      />



      <div className="formWrapper">

        <div className='tabs pb-5'>
          <ul className='flex cursor-pointer'>
            <li className={`py-2 px-6  ${plan === "Beginner" ? 'bg-[#2B3674] text-white' : 'bg-white text-gray-500 bg-gray-200'}  rounded-t-lg`} onClick={() => handleChangePlan("Beginner")}>Beginner</li>
            <li className={`py-2 px-6 ${plan === "Advanced" ? 'bg-[#2B3674] text-white' : 'bg-white text-gray-500 bg-gray-200'} rounded-t-lg`} onClick={() => handleChangePlan("Advanced")}>Advanced</li>
            <li className={`py-2 px-6 ${plan === "Premium" ? 'bg-[#2B3674] text-white' : 'bg-gray text-gray-500 bg-gray-200'} rounded-t-lg`} >Premium</li>
          </ul>
        </div>


       


        <h1 className="trending-creators">Create Campaign</h1>
        <h1 className="campaign_info">
          Please fill this form correctly to create your campaign!
        </h1>
        {CForm(
          formData,
          (updatedDate: formBuilder[]) => {
            console.log(getJson(updatedDate));
          },
          0,
          10
        )}
         {plan === "Advanced" && <>

<div className="flex">
  <div className="bg-white pt-4 pb-4 w-96 rounded-md">
    <h1 className="text-lg font-bold text-gray-500 text-left">Search creator</h1>
    <div className="mt-5 mb-2 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
      <input value={creatorName} onChange={(e) => setCreatorName(e.target.value)} className="flex-grow outline-none text-gray-600 focus:text-blue-600" type="text" placeholder="Search creator..." />
      <span onClick={() => setSearch(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </span>
    </div>
  </div>
</div>



{search === true &&
  <div className="bg-white pt-5 pb-5 rounded-md w-[100%] m-auto">
    <div className=" flex items-center justify-between pb-6">
      <div>
        <h2 className="text-gray-600 font-semibold">Creators</h2>

      </div>

    </div>
    <div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Name
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Total Views
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>

                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">

                </th>
              </tr>
            </thead>
            <tbody>

              {search === true && creator?.map((creator) => (
                <tr className="text-left">
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img className="w-full h-full rounded-full"

                          src={creator.identificationImage}
                          alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {creator.FirstName + " " + creator.LastName}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{creator.totalViews === null ? 0 : creator.totalViews}</p>
                  </td>


                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span
                      className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                      <span className="relative">Active</span>
                    </span>
                  </td>

                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button className="bg-indigo-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">request</button>
                  </td>
                </tr>))

              }




            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
}

</>
}
        <button
          disabled={loading}
          onClick={async () => {
            // console.log(getJson(formData));
            setTimeout(async () => {
              setPopUp(true);
              setPopUpTitle("Campaign Budget");
              var body: any = getJson(formData);
              body.shortVideoUrl = videoFile;
              var budget = await getCampaignBudget(getJson(formData));
              body.budget = budget;
              setCampainBody(body);

              setPopUpMessage(
                budget +
                " Birr is the budget for the campaign according to the details you have provided. Please confirm the budget and proceed to the next step."
              );
            }, 2000);
          }}
          className="saveBut"
        >
          <Loading loading={loading} setLoading={setLoading} />

          {loading ? "saving...." : "Save Campaign"}
        </button>
        
      </div>
      

      {/* <div className="buttonArea"></div> */}
    </div>
  );
}
