import React from 'react';

interface Props {
    expectedView: string;
    setExpectedView: (value: string) => void;

}

const ExpectedView: React.FC<Props> = ({ expectedView, setExpectedView }) => {


    return (
        <div className="fadeIn">
            <p className='question'>  Expected View</p>

            <div className="flex flex-row items-center justify-start ">
                <input type='text'
                    className="expectedView"
                    placeholder="Enter expected View..."
                       value={expectedView}
                    onChange={(event: any) => {
                        console.log(event.target.value)
                        if(event.target.value == ""){
                            console.log("FINALHHJ1: ", event.target.value);
                            setExpectedView("");
                        }else{
                            console.log("FINALHHJ: ", event.target.value);
                            let value = event.target.value.replace(/,/g, '');
                            let parsedValue = parseInt(value);

                            setExpectedView(parsedValue.toLocaleString());
                        }
                    }}
                />
                {/* <p style={{ textAlign: 'start', color: 'gray', fontSize: 34 }}>Views</p> */}

            </div>

        </div>
    );
};

export default ExpectedView;
