// CampaignDescription.tsx
import React from 'react';
import { AiFillAlert, AiFillMessage, AiFillNotification } from 'react-icons/ai';

export interface ErrorComponentProps {
  errorTitle: string;
  errorMessage: string;
  posetiveAction: () => void;
  negativeAction: () => void;
  type: string;
  posetiveText?: string;
    negativeText?: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ errorTitle , errorMessage , negativeAction , posetiveAction, type , posetiveText, negativeText }) => {
 

  return (
    <div className="parent">
   
   
    <div className="popup">
        <div className={`popup-inner-${type}`}>
          { type == "message" &&
            <AiFillMessage className='iconPopup' width={500} color={'white'} />
            }
           { type == "warning" &&
            <AiFillNotification className='iconPopup' width={500} color={'white'} />
           }

           { type == "error" &&
            <AiFillAlert className='iconPopup' width={500} color={'white'} />
              }

            
            <h1 className='popupInner'> {type} </h1>
        </div>
            <div className="popup-inner_right">
    <p className='question'>{errorTitle}</p>
    <p className='errorContent'>{errorMessage}</p>
    <div className="button-container">
    <button onClickCapture={() => {
        console.log("NEGATIVE ACTION");
        negativeAction();
    }} className="negativeButton" onClick={negativeAction}>{negativeText? negativeText : "No"}</button>
    <button onClickCapture={() => {
        console.log("POSITIVE ACTION");
        posetiveAction();
    }} className="posetiveButton" onClick={posetiveAction}>{posetiveText? posetiveText : "Yes" }</button>
    </div>
    </div>
    </div>
   
  </div>
  );
};

export default ErrorComponent;
