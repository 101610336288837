import { userProp } from "../screens/auth/prop.creator";



export function getToken(): string{
   var userData = localStorage.getItem("UserInfo");
    if(userData){
         return JSON.parse(userData).token;
    }else{
        return "";
    }
}

export function getUserInfo(): userProp{
    
    var userData = localStorage.getItem("UserInfo");
    if(userData && JSON.parse(userData).creatorDetail){
         var userInfo = JSON.parse(userData);
         var userProp = {
                "firstName": userInfo.creatorDetail.firstName,
                "lastName": userInfo.creatorDetail.lastName,
                "profilePicture": userInfo.creatorDetail.identificationImage,
                "token": userInfo.token,
                "email": userInfo.creatorDetail.email,
                "userType": "CREATOR"
         }
         console.log("hkjhkj: ", userProp);

         return userProp;   

    }else if(userData && JSON.parse(userData).businessDetail){
     var userInfo = JSON.parse(userData);
          userProp = {
                "firstName": userInfo.businessDetail.name,
                "lastName": "",
                "profilePicture": "",
                "token": userInfo.token,
                "email": userInfo.businessDetail?.email,
                "userType": "BUSINESS"
         };
         console.log("hkjhkj: ", userProp);

         return userProp;   
    } else{
        console.log("No user data found");
        return {"firstName":"test","lastName":"","profilePicture":"","token":"","email":""} as userProp;
    }
}

export function getUserType(){
    var userData = localStorage.getItem("UserInfo");
    if(userData){
         return JSON.parse(userData).body.userType;
    }else{
        return "";
    }
}

export function isTokenValid(){
    var userData = localStorage.getItem("UserInfo");
    if(userData){
        return true;
    }else{
        return false;
    }
}