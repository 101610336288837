
import { useState } from "react";
import "./style.faq.css";


export default function Markating(){
    const [selected_faqs, setSelectedFaqs] = useState<number>(0);
    let marketing_strategies = [
        {
            title: "YouTube",
            content: [
                {
                    question: "What is YouTube marketing?",
                    answer: "YouTube marketing involves creating video content to promote a brand, product, or service. This can include tutorials, testimonials, or entertainment content to engage and attract an audience."
                },
                {
                    question: "How do I get started with YouTube marketing?",
                    answer: "To get started with YouTube marketing, create a YouTube channel, produce high-quality videos, optimize video titles and descriptions for SEO, and engage with your audience through comments and social media."
                }
            ]
        },
        {
            title: "Telegram",
            content: [
                {
                    question: "What is Telegram marketing?",
                    answer: "Telegram marketing involves using the Telegram messaging app to communicate with and engage your audience. This can include sharing updates, promotions, and exclusive content through channels or groups."
                },
                {
                    question: "How do I get started with Telegram marketing?",
                    answer: "To get started with Telegram marketing, create a Telegram channel or group, invite your audience to join, regularly post valuable content, and interact with your members to build a community."
                }
            ]
        },
        {
            title: "TikTok",
            content: [
                {
                    question: "What is TikTok marketing?",
                    answer: "TikTok marketing involves creating short, engaging videos to promote a brand, product, or service on the TikTok platform. This can include challenges, tutorials, or behind-the-scenes content to attract a younger audience."
                },
                {
                    question: "How do I get started with TikTok marketing?",
                    answer: "To get started with TikTok marketing, create a TikTok account, produce creative and entertaining videos, use popular hashtags to increase visibility, and engage with your audience through comments and collaborations."
                }
            ]
        }
    ];
    

    return (
        <div className="container">
        <h1>Marketing Strategies</h1>
        <div className="faq-sections">
        {marketing_strategies.map((section, index) => (
            <div onClick={()=>{
                setSelectedFaqs(index);
            }} key={index} className={index == selected_faqs ? "faq-header-section-selected" : "faq-header-section"} >
            <h2>{section.title}</h2>
            </div>
        ))}
        </div>
        <div className="faq-content">
        {marketing_strategies[selected_faqs].content.map((faq, index) => (
            <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
            </div>
        ))}
        </div>
        </div>
    )
}