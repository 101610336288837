import "./video.style.css"

interface Props {
    title: string,
    url: string,
    description: string
}

export default function VideoCard({title, url, description}:Props) {
    return (
        //   <video classNameName="h-full w-[40%] rounded-lg p-[2%]" controls>
        //     <source src="https://docs.material-tailwind.com/demo.mp4" type="video/mp4" />
        //     Your browser does not support the video tag.
        //   </video>
        <div className="videoCard">
            <div className="rounded-lg shadow-lg bg-white max-w-xl">
                <a href="#!">
                    <video width="320" height="240" controls className="w-full rounded-t-lg">
                        <source src={url} type="video/mp4" />
                        <source src="movie.ogg" type="video/ogg" />
                          
                            </video>
                </a>
                        <div className="p-6">
                            <h5 className="text-[#6968FF] text-xl font-medium mb-2">{title}</h5>
                            <p className="text-black text-base mb-4">
                                {description}
                            </p>
                            <button type="button" className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button>
                        </div>
                    </div>
            </div>
 

            );
  }