import "./style.profile.css";
import "./style.mobile.profile.css";
import {
  BsBank,
  BsFacebook,
  BsGearWideConnected,
  BsTiktok,
  BsYoutube,
} from "react-icons/bs";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import { userProp } from "../../auth/prop.creator";
import { getUserInfo } from "../../../util/controller";
import SocialMediaConnectComponent from "../../../component/socialMediaConnectComponent/socialMediaConnectComponent";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "../../../component/connectComponent/youtube";
import { facebook_oauth_link, google_oauth_link } from "../../../util/constant";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { getBusinessDetails, getSocialMedia, getTotalView } from "./controller.profile";
import { Loading } from "../../../component/loading/loading";

import CreateAccount from "../../create_account/create_account";

import UpdateProfile from "./updateProfile";
import { RiTelegramFill } from "react-icons/ri";
import { MdEditSquare, MdNumbers, MdOutlineLocationCity, MdPerson } from "react-icons/md";
import { FaMapLocation, FaTreeCity } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import ConnectTelegram from "../../dashboard/telegram/connetTelegram/connectTelegram";
import { getConnectionToken } from "../../dashboard/telegram/telegram_list/controller.telegram";
import { AiOutlineNumber } from "react-icons/ai";
export default function BusinessProfile() {
  const navigator = useNavigate();
  const [userInfo, setUserInfo] = useState<userProp>(getUserInfo());
  const [socialMediaAccounts, setSocialMediaAccounts] = useState<string[]>([]);
  const [isYoutubeConnected, setIsYoutubeConnected] = useState<boolean>(false);
  const [totalView, setTotalView] = useState<number>(0);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
  const [creatorDetail, setCreatorDetail] = useState<any>({});
  const [connectTelegram, setConnectTelegram] = useState<boolean>(false);
  const [telegramConnectToken, setTelegramConnectToken] = useState<string>("");
  const [updateProfile, setUpdateProfile] = useState<boolean>(false);


  const [editTinNumber,setEditTinNumber] = useState<boolean>(false);
  const [tinInfo,setTinInfo] = useState<any>({});

  const [editeBankInfo,setEditBankInfo] = useState<boolean>(false);
  const [bankInfo,setBankInfo] = useState<any>({});
  const [reloadContent, setReloadContent] = useState<boolean>(false);


  let name = userInfo?.firstName + " " + userInfo?.lastName;
  useEffect(() => {
    getBusinessDetails().then((res: any) => {
      setCreatorDetail(res);
      console.log(res);
    }).catch((err) => {
      console.log(err)

    })

  }, []);

  useEffect(() => {
    getBusinessDetails().then((res: any) => {
      setCreatorDetail(res);
      console.log(res);
    }).catch((err) => {
      console.log(err)

    })

  }, [reloadContent]);



  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     const video = document.createElement('video');
  //     video.onloadedmetadata = () => {
  //       const durationInSeconds = Math.floor(video.duration);
  //       const hours = Math.floor(durationInSeconds / 3600);
  //       const minutes = Math.floor((durationInSeconds % 3600) / 60);
  //       const seconds = durationInSeconds % 60;
  //      ;
  //     };
  //     video.src = URL.createObjectURL(file);
  //     props?.onChange(file);
  //   }
  // };




  return (
    <div>
    <div className="profileFatherComponent">
      {connectTelegram &&
        <ConnectTelegram closeTelegram={() => { setConnectTelegram(false) }} token={telegramConnectToken} />
      }
      {updateProfile &&
        <UpdateProfile closeComponent={()=>{setUpdateProfile(false)}} />}
      <div style={{ width: 650, height: '100%', backgroundColor: '#090D25', margin: 15, borderRadius: 5, overflow: 'hidden' }}>
        <div style={{position:'relative', width: '100%', height: 320, overflow: 'hidden', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #090D25 100%)', }}>
          <img src={userInfo.profilePicture} style={{ width: '100%', borderRadius: 5, alignSelf: 'center', objectFit: 'cover' }} />
        <button
        onClick={()=>{
          setUpdateProfile(true)  ;
        }}
        style={{width: 50, height: 50 ,padding:5, bottom: 20,right:20, position:'absolute',backgroundColor:'#090D25',borderRadius:10}}> 
      <MdEditSquare style={{ margin: 5, fontSize: 35 }} color='white' />        

        </button>
        </div>
        <h1 style={{ color: 'white', fontSize: 30, margin: 20, marginBottom: 0 }}>{userInfo.firstName + " " + userInfo.lastName}</h1>
        <p style={{ color: 'lightgray', fontSize: 17 }}> {userInfo.email} </p>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', backgroundColor: '#0088cc', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
          <div style={{ display: 'flex' }}>
            <RiTelegramFill style={{ margin: 5, fontSize: 35 }} color='white' />
            <p style={{ margin: 5, fontSize: 23 }} >Telegram</p>
          </div>

          <button
            onClick={() => {
              if (creatorDetail.telegramId) {
                getConnectionToken().then((res: any) => {

                  setTelegramConnectToken(res.token);
                }).catch((err) => {

                })
              } else {

              }

            }}

            style={{ padding: 5, borderRadius: 5 }}> {creatorDetail.telegramId ? "connected" : "Connect"}</button>
        </div>
        <p style={{ color: 'gray', width: '85%', margin: 'auto', marginTop: 10 }}>You can be notified with latest update after connecting your telegram account, you can also run ads on your telegram channels after connecting the account</p>
      </div>
      <div style={{ width: '65%', height: '80vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', margin: 15, justifyContent: 'space-between', alignItems: 'space-between' }}>

        <div style={{ height: '49%', width: 800, backgroundColor: '#090D25', borderRadius: 5, overflow: 'hidden', overflowY: 'scroll' }}>
          <div style={{ width: '100%', height: 70, padding: 5, display: 'flex', color: 'white', flexDirection: 'row', backgroundColor: '#0088cc', justifyContent: 'center', alignItems: 'center' }}>
            <h1> Location Information</h1>
          </div>


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <MdOutlineLocationCity style={{ margin: 5, fontSize: 35, color: 'white' }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >{creatorDetail.woreda}</p>
            </div>

            <button> {"03"} </button>
          </div>



          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <MdOutlineLocationCity style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >SubCity</p>
            </div>

            <button> {creatorDetail.subCity} </button>
          </div>


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <FaTreeCity style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >City</p>
            </div>

            <button> {creatorDetail.city} </button>
          </div>


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <FaMapLocation style={{ margin: 5, fontSize: 35, color: 'white' }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Region</p>
            </div>

            <button> {creatorDetail.region} </button>
          </div>


        </div>

        <div style={{ height: '49%', width: 800, backgroundColor: '#090D25', borderRadius: 5 }}>
          <div style={{ width: '100%', height: 70, padding: 5, display: 'flex', color: 'white', flexDirection: 'row', backgroundColor: '#0088cc', justifyContent: 'center', alignItems: 'center' }}>
            <h1> Bank Information</h1>
            <MdEditSquare
            onClick={()=>{
              setEditBankInfo(true);
            }}
            style={{ margin: 5, fontSize: 35 }} color='white' />        

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <BsBank style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Bank Name</p>
            </div>
              {editeBankInfo ?
               // add a drop down to select the bank name
                <select onChange={(e) => {
                  setBankInfo({...bankInfo,bankName:e.target.value});
                } } style={{backgroundColor:'#090D25',color:'white',border:'none',borderRadius:5,padding:5}}>
                  <option value="Awash Bank">Awash Bank</option>
                  <option value="Commercial Bank of Ethiopia">Commercial Bank of Ethiopia</option>
                  <option value="Dashen Bank">Dashen Bank</option>
                  <option value="Wegagen Bank">Wegagen Bank</option>
                  <option value="CBE">CBE</option>
                </select>
                : 
                <button> {creatorDetail.bankName ? creatorDetail.bankName : "NA"} </button>
              }
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <MdPerson style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Name</p>
            </div>
              
              {editeBankInfo ?
              <input 
                onChange={(e) => {

                    setBankInfo({...bankInfo,bankUserName:e.target.value});

                }}
              style={{color:'black'}} />
              :
              <button> {creatorDetail.bankUserName ? creatorDetail.bankUserName : "NA"} </button>
            }
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <MdNumbers style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Bank Number</p>
            </div>
            {editeBankInfo ?
              <input 
                onChange={(e) => {
                                      setBankInfo({...bankInfo,bankNumber:e.target.value});

                }}
              style={{color:'black'}} />
              :
              <button> {creatorDetail.bankAccountNumber ? creatorDetail.bankAccountNumber : "NA"} </button>
            }  
          
          </div>
            {editeBankInfo && 
            <button 
            onClick={()=>{
              // alert("Bank Information Updated, " + JSON.stringify(bankInfo));
              // api call to update the bank information
            //   updateBankInfo(bankInfo).then((res: any)=>{
            //     console.log(res);
            //     setReloadContent(!reloadContent);
            //   }
            //   ).catch((err: any)=>{
            //     console.log(err);
            //   });
            //   setEditBankInfo(false);
            }
            }
            style={{backgroundColor:'#0088cc',color:'white',border:'none',borderRadius:5,padding:5}}>Save</button>
            }


        </div>


      </div>
      
            

    </div>
    <div style={{ height: '49%', width: '90%', backgroundColor: '#090D25', borderRadius: 5,marginLeft: 20,marginTop: 40,overflow: 'hidden',marginBottom: 30 }}>
          <div style={{ width: '100%', height: 70, padding: 5, display: 'flex', color: 'white', flexDirection: 'row', backgroundColor: '#0088cc', justifyContent: 'center', alignItems: 'center' }}>
            <h1> Tin Number information</h1>
            <MdEditSquare
            onClick={()=>{
              setEditTinNumber(true);
            }}
            style={{ margin: 5, fontSize: 35 }} color='white' />        

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <AiOutlineNumber style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Tin Number</p>
            </div>
              {editTinNumber ?
               // add a drop down to select the bank name
              <input onChange={(e) =>{
                setTinInfo({...tinInfo,tinNumber:e.target.value});
              }} style={{color: 'black'}} />
                : 
                <button> {creatorDetail.tinNumber ? creatorDetail.tinNumber : "NA"} </button>
              }
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white', width: '90%', alignSelf: 'center', margin: 'auto', marginTop: 10, padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
              <MdPerson style={{ margin: 5, fontSize: 35 }} color='white' />
              <p style={{ margin: 5, fontSize: 23 }} >Licence </p>
            </div>
              
              {editTinNumber ?
              <input
              className="block p-2 font-sans font-normal w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="file_input_help"
              name="file_input"
              type="file"
              // accept=".png,.jpg,.jpeg"
            //   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                
            //     const file = e.target.files && e.target.files[0];
            //     // setUpdate(file);
            //     props.onChange(file);
                
            //   }
            // }
            onChange={(e)=>{

            }}
           
              // onInputCapture={(val) => {
              //     console.log("vakjhlsfkhal");
                  
              //   props.onChange("hello");
              // }}
            />
              :
              <button> {creatorDetail.licence ? creatorDetail.licence : "NA"} </button>
            }
          </div>

            {editTinNumber && 
            <button 
            
              onClick={()=>{
                // updateTinInfo(tinInfo).then((res: any)=>{ 

                //     setReloadContent(!reloadContent);
                //     setEditTinNumber(false);
                //   }
                //   ).catch((err: any)=>{
                //     console.log(err);
                //   });

              }}

            style={{backgroundColor:'#0088cc',color:'white',border:'none',borderRadius:5,padding:5}}>Save</button>
            }

        </div>
    </div>
  );
}
