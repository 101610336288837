import React, { useState } from 'react';
import { handlePlanClick } from '../../../../util/campaign/createCampaign';
import { RiAddLine, RiGitPullRequestFill, RiLineLine, RiMediumLine, RiPulseLine } from 'react-icons/ri';
import { AiOutlineLine } from 'react-icons/ai';

interface Props {
    WebsiteList: any[];
    setWebsiteList: React.Dispatch<React.SetStateAction<any[]>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const WebsiteCollector: React.FC<Props> = ({ WebsiteList, setWebsiteList, setPages }) => {

    const [linkType, setLinkType] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [websiteListLocalized,setWebsiteListLocalized] = useState<any[]>([{
        "type": "social_media",
        "website": "YouTube",
        "url": ""
      }]);


    return (
        <div className="fadeIn">
            <p className='question'> Insert Your SocialMedia Or Website</p>
            <p style={{ textAlign: 'start', color: 'gray', fontSize: 17 }}>
                The information you provide here will be used in a page that will be linked and putted under the campaign videos, please Verify you have put the correct link becuse this data might determine the result of your campaign
            </p>

          {websiteListLocalized.map((collectedData: any,index: number) => {

            return(
            <div className="website-linker-container" key={index}>
                <select name="type" value={collectedData.type}  onChange={(e: any) => {
                    // let prevValue = [...websiteListLocalized];
                    // prevValue[index].type = e.target.value;
                    // console.log("FINALHHJ: ", prevValue);
                    // setWebsiteListLocalized(prevValue);

                    setWebsiteListLocalized(prevValue => {
                        const newState = prevValue.map((item, i) =>
                        i === index ? { ...item, type: e.target.value } : item
                    );
                    return newState;
                    
                    })

                    
                    
                }}>
                    <option value="social_media" >Social Media</option>
                    <option value="website" >Website</option>
                </select>
                {collectedData.type == "social_media" &&
                    <select name="social_media" onChange={(e: any) => {

                        //setSocialMedia(e.target.value);
                    }}>
                        <option value="YouTube" >YouTube</option>
                        <option value="Tiktok" >Tiktok</option>
                        <option value="Instagram" >Instagram</option>
                        <option value="Facebook" >Facebook</option>

                    </select>
                }

                <input type='url' name="social_media_url" />


                <button onClick={()=>{
                      setWebsiteListLocalized(prevState => {
                        const newState = [...prevState];
                        newState.splice(index, 1);
                        return newState;
                    });
                }} className="negative">
                    <AiOutlineLine width={50} height={50} />
                </button>
                <button onClick={() => {
                    let prevValue = [...websiteListLocalized];
                    let toBeRepeated = prevValue[index];
                    prevValue.push(toBeRepeated);
                      setWebsiteListLocalized(prevValue);
                
                        
                }}>
                    <RiAddLine width={50} height={50} />
                </button>



            </div>
            )
          })}
            
        </div>
    );
};

export default WebsiteCollector;
