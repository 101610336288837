

export default function Policy() {
    return (
        <iframe
        src={`${process.env.PUBLIC_URL}/policy.pdf`}
        height={window.innerHeight}
        width="100%"
        ></iframe>
    );
    }