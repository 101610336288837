import { useFormik } from "formik";
import * as Yup from "yup";
const BusinessSigninSchemaStep1 = Yup.object().shape({
    username: Yup.string().required("Username Required"),
    password: Yup.string().required("Password Required"),
    businessName: Yup.string().required("Business Name Required"),
    email: Yup.string().email().required("Email is Required"),
    
});
const BusinessSigninSchemaStep2 = Yup.object().shape({
    address: Yup.string().required("Address is Required"),
    licenseCertificate: Yup.mixed()
    .required('License Certificate is required')
    .test(
      'fileType',
      'Only image files with extensions jpg, jpeg, png are allowed',
      (value) => {
        if (!value) return false; // Reject if no file is provided
        const file = value as File;
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileName = file.name || ''; // Ensure fileName is a string
        const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? ''; // Ensure fileExtension is a string
        return allowedExtensions.includes(fileExtension);
      }
    ),
});


export const businessSignupFormikStep1 = {
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: { username: "", password: "", businessName: "",email: "" },
    validationSchema: BusinessSigninSchemaStep1,
    onSubmit: (values:any, error:any) =>{ if(error) {
        return error;
    } console.log(values)} 

};
export const businessSignupFormikStep2 = {
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: { address: "", licenseCertificate: "" },
    validationSchema: BusinessSigninSchemaStep2,

};

