import React, { useEffect, useRef, useState } from 'react';
import './ChatBox.css';
import { messageContent } from './chatContent';
import { SiGooglemeet } from 'react-icons/si';
import io from 'socket.io-client';
import { AiFillWechat } from 'react-icons/ai';

const socket = io('https://api.zeterads.com');

const ChatBox = ({ campaignId, userId }: any) => {
  const [messages, setMessages] = useState(messageContent);
  const [newMessage, setNewMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false); // State to manage visibility
  const chatBoxRef = useRef<any>(null); // Create a ref for the chat box

  useEffect(() => {
    socket.emit('joinCampaign', campaignId);

    socket.on('messages', (data) => {
      console.log("data", data);
      setMessages(data);
    });

    socket.on('message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('meetLink', (link) => {
      console.log('Google Meet link:', link);
      // Handle Google Meet link
    });

    return () => {
      socket.off('messages');
      socket.off('message');
      socket.off('meetLink');
    };
  }, [campaignId]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      socket.emit('sendMessage', { campaignId: campaignId , sender: 'user', text: newMessage, userId: userId });
      setNewMessage('');
    }
  };

  const handleStartMeeting = () => {
    const meetUrl = "https://meet.google.com/new"; // This creates a new meeting
    window.open(meetUrl, '_blank');
  };

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    chatBoxRef.current?.scrollTo({
      top: chatBoxRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  const toggleChatBox = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div>
      <button className="toggle-button" onClick={toggleChatBox}>
        <AiFillWechat size={35} />
      </button>
      {isVisible && (
        <div className="chat-container">
          <div className="chat-box" ref={chatBoxRef}>
            {messages.map((message: any, index) => (
              <div key={index} className={userId === message.userId ? 'chat-bubble user' : 'chat-bubble bot'}>
                <p>{message.text}</p>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input
              type="text"
              className="chat-input"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button className="send-button" onClick={handleSendMessage}>
              Send
            </button>
            <button className="meet-button" onClick={handleStartMeeting}>
              <SiGooglemeet size={25} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
