import React from "react";
import { AiFillApi, AiFillCalculator, AiOutlinePayCircle, AiOutlinePullRequest } from "react-icons/ai";
import "./style.orders.css";
import "./style.mobile.orders.css";
import { AlignVerticalDistributeCenter } from "lucide-react";
import { CircularProgress } from "@mui/material";
import { BsCameraReels, BsPersonGear } from "react-icons/bs";
import { Ri24HoursFill } from "react-icons/ri";
import { MdDoneOutline } from "react-icons/md";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { TbBusinessplan, TbCameraQuestion } from "react-icons/tb";

interface campaignDetailStatusProp {
  status: string;
}

export default function OrderStatus({ status }: campaignDetailStatusProp) {

  const getStatusNumber = (status: string) => {
    switch (status) {
      case "Requested":
        return 1;
      case "nominee":
        return 1;
      case "Accepted":
        return 2;
      case "production":
        return 3;
      case "Progress":
        return 4;
      case "Completed":
        return 5;
      default:
        return 0;
    }
  }

  const getClassName = (status: string , index: number) => {
    console.log("STATUS: ",status, getStatusNumber(status) , " = = ", index);
    // if (getStatusNumber(status) == index) {
    //   return "stepper-item active";
    // } else
    if (getStatusNumber(status) >= index) {
      return "stepper-item completed";
    } else {
      return "stepper-item";
    }
  }

  return (
    <div className="stepper-wrapper">
  <div className={getClassName(status,1)}>
    <div className="step-counter">
    <TbCameraQuestion style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Requested</div>
  </div>
  <div className={getClassName(status,2)}>
    <div className="step-counter">
    <TbBusinessplan style={{ color: "white", fontSize: 17 }} />
    </div>
    <div className="step-name">Approval</div>
  </div>
  <div className={getClassName(status,3)}>
    <div className="step-counter">
    <BsCameraReels style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Production</div>
  </div>
  <div className={getClassName(status,4)}>
    <div className="step-counter">
    <BsPersonGear style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Progress</div>
  </div>
  <div className={getClassName(status,5)}>
    <div className="step-counter">
    <IoCheckmarkDone style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Completed</div>
  </div>
</div>
  );
}


/*

 return (
    <div className="containerStatus">
      <div className="inner-container">
        <div className="headerBoxer">
          <div className={status == "Requested"?  "highlighted-circle" : "circle"  }>
            <TbCameraQuestion style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Requested</p>
        </div>
        <div className="headerBoxer">
          <div className={status == "Requested"?  "highlighted-circle" : "circle"  }>
            <TbBusinessplan style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Business Approval</p>
        </div>
        <div className="headerBoxer">
          <div className={status == "accepted"?  "highlighted-circle" : "circle"  }>
            <BsCameraReels style={{ color: "white", fontSize: 30 }} />
          </div>
          <p>Production</p>
        </div>
        <div className="headerBoxer-highlighted">
        <div className={status == "progress"?  "highlighted-circle" : "circle"  }>
          <BsPersonGear style={{ color: "white", fontSize: 40 }} />
        </div>
        <p>Progress</p>
        </div>
        <div className="headerBoxer">
          <div className="circle">
            <IoCheckmarkDone style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Completed</p>
        </div>
      </div>
    </div>
  );

*/