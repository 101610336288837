

import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { Navigation, Ticket, View } from "lucide-react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { google_oauth_link, url } from "../../../util/constant";
import axios from "axios";
import { AiFillDollarCircle, AiFillMoneyCollect, AiFillYoutube, AiOutlineExpandAlt, AiOutlineFundView } from "react-icons/ai";
import { FaPeopleGroup, FaToggleOff, FaToggleOn } from "react-icons/fa6";
import { IoIosRefreshCircle, IoMdRefresh } from "react-icons/io";
import { TbTargetArrow } from "react-icons/tb";
import { IoCloudUpload } from "react-icons/io5";
import { enableTelegramAccount } from "../telegram/telegram_list/controller.telegram";
import { useState } from "react";
import { Button, Input } from "@mui/material";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdMoneyOff } from "react-icons/md";
import axiosInstance from "../../../axiosConfig";

export function GetColumns(updateContent: any, updatedContent: any[], nicheList: any[], ageGroup: any[]): GridColDef[] {
  //            setColumns(getColumns((avgViews: any) => { setUpdateContent(avgViews) },updatedContent, nicheList, ageGroup));

  const [forceRender, setForceRender] = useState(false);


  const ToggleEnabler = (params: any) => {

    const [enabled, setEnabled] = useState(params.row.status);
    return (

      <Button onClick={() => {
        enableTelegramAccount({ socialMediaAccountId: params.row.id, enabled: !enabled }).then((res: any) => {
          setEnabled(!enabled);
        }
        ).catch((err: any) => {

        });

      }} className="actionButton" style={{ display: 'flex', flexDirection: 'row', margin: 5, color: 'black' }} >
        {enabled &&
          <FaToggleOn style={{ fontSize: 43, marginLeft: 4 }} />
        }
        {!enabled &&
          <FaToggleOff style={{ fontSize: 43, marginLeft: 4 }} />
        }
      </Button>
    )

  }
  const columns: GridColDef[] = [
 
    { field: 'accountName', headerName: 'Account Name', width: 150 },
    // {
    //   field: 'accountLink', headerName: 'Account Link', width: 150,
    //   renderCell: (params: any) => {
    //     return (
    //       <span style={{ color: 'brown', fontWeight: 'bolder', justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    //         <AiFillYoutube
    //           onClick={() => {
    //             window.location.href = params.row.accountLink;
    //           }}
    //           style={{ fontSize: 38, color: 'red', margin: 3 }} />
    //         Youtube
    //       </span>
    //     )
    //   }
    // },
    {
      field: 'subCount', headerName: 'Sub Count', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
            {params.value.toLocaleString()}
            <FaPeopleGroup style={{ fontSize: 23 }} />
          </span>
        )
      }
    },
    {
      field: 'rate', headerName: 'Per 1000 view', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
           
            <Input 
              onChange={(e: any) => {
              const newValue = e.target.value;
              params.row.rate = newValue; // Update the params object
              params.value = newValue;
              let oldContent = { ...updatedContent };
              if (oldContent[params.row.id] == undefined) {
                oldContent[params.row.id] = {};

              }
              oldContent[params.row.id]["rate"] = newValue;
              console.log("COntent: ",oldContent);

              // setForceRender(!forceRender);
              updateContent(oldContent)
            }
            }

            value={params.value} style={{  border:'1px black solid', borderRadius: 5, color: 'black', width: '100%' }} type="number"  />
          </span>
        )
      }
    },
    {
      field: 'avgView', headerName: 'Avg View', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
            {params.value.toLocaleString()}
            <AiOutlineFundView style={{ fontSize: 23 }} />
          </span>
        )
      }
    },
    {
      field: 'Niche', headerName: 'Niche', width: 150,
      renderCell: (params: any) => {
        const sortedNicheList = nicheList
        ? [...nicheList].sort((a, b) => {
            if (a.id === params.row.nicheId) return -1; // Move selected niche to the top
            if (b.id === params.row.nicheId) return 1;
            return 0;
          })
        : [];
        return (
          <select onChange={(e: any) => {
            console.log("COntent: ");

            const newValue = e.target.value;
            params.row.ageGroup = newValue; // Update the params object
            params.value = newValue;
            let oldContent = { ...updatedContent };
            if (oldContent[params.row.id] == undefined) {
              oldContent[params.row.id] = {};
            }
            oldContent[params.row.id]["nicheId"] = newValue;
            // setForceRender(!forceRender);
            

            updateContent(oldContent)
          }} style={{ padding: 10, borderRadius: 5, color: 'black', width: '100%' }}>
            {sortedNicheList && sortedNicheList.map((niche: any) => {
             
              return (
                <option key={niche.id} value={niche.id} style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                  <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                    {niche?.name}
                    <TbTargetArrow style={{ fontSize: 23 }} />
                  </span>
                </option>
              )
            
            })
            }
          </select>

        )
      }
    },
    {
      field: 'audience',
      headerName: 'Audience Age',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params: any) => {
        const sortedAgeGroup = ageGroup
        ? [...ageGroup].sort((a, b) => {
            if (a.id === params.row.id) return -1; // Move selected niche to the top
            if (b.id === params.row.id) return 1;
            return 0;
          })
        : [];


        return (

          <select onChange={(e: any) => {
           

            const newValue = e.target.value;
            params.row.ageGroup = newValue; // Update the params object
            params.value = newValue;
            let oldContent = { ...updatedContent };
            if (oldContent[params.row.id] === undefined) {
              oldContent[params.row.id] = {};
            }
            oldContent[params.row.id]["audienceAge"] = newValue;
            console.log("tobeupdated: ", oldContent);
            //  alert("OldContent: "+ JSON.stringify(oldContent));
            updateContent(oldContent)
          }} style={{ padding: 10, borderRadius: 5, color: 'black' }}>
            {sortedAgeGroup && sortedAgeGroup.map((age: any) => {

              return (
                <option value={age?.id} style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                  <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                    {age?.name}
                    <TbTargetArrow style={{ fontSize: 23 }} />
                  </span>
                </option>
              )
            })
            }

          </select>
        );
      },
      width: 160,

    },
    {
      field: 'e',
      headerName: 'Enabled',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: ToggleEnabler
    },
    {
      field: 'Monitization',
      headerName: 'Monitization',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (param: any) => {
        if(param.row.avgView > 59 && param.row.subCount > 59){
        return(
          <div style={{display:'flex'}}>
            <GiTakeMyMoney style={{fontSize: 33,color:'green'}}/>
          </div>
        )
      }else{
        return(
          <div style={{display:'flex'}}>
          <MdMoneyOff style={{fontSize: 33,color:'red'}}/>
        </div>
        )
      }
      }
    },
    {
      field: '',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params: any) => {



        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>


            <Button  onClick={()=>{
              window.open(google_oauth_link);

            }} className="actionButton" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 5, backgroundColor: 'rgb(91, 31, 31)' }} >

              <IoMdRefresh style={{ fontSize: 23, marginLeft: 4,color:'white' }} />
            </Button>

            <Button onClick={() => {
              console.log("UPDATEDDDD: ", JSON.stringify(updatedContent));
                  let socialMediaAccountId = params.row.id;
                 let nicheId = updatedContent[params.row.id]["nicheId"] ? updatedContent[params.row.id]["nicheId"] : params.row.nicheId;
                  let audienceAge = updatedContent[params.row.id]["audienceAge"] ? updatedContent[params.row.id]["audienceAge"] : params.row.audienceAge;
                  let rate = updatedContent[params.row.id]["rate"] ? updatedContent[params.row.id]["rate"] : params.row.rate;
                  rate = rate ? parseInt(rate) : 0;
                  updateYoutubeAccount({socialMediaAccountId: socialMediaAccountId,nicheId:nicheId,audienceAge:audienceAge,rate:rate }).then((res:any)=>{
                    console.log("TEST: ",res); 
                  }).catch((err:any)=>{
                    console.log("TEST: ",err);
                  })
                }} className="actionButton" style={{ display: 'flex', margin: 5, flexDirection: 'row', backgroundColor: '#1c0d05',color:'white' }} >
              Update
              <IoCloudUpload style={{ fontSize: 23, marginLeft: 4 }} />
            </Button>


          </div>
        );
      },
      width: 280,

    }
  ];




  return columns;
}





export function getRows(): any {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
    axiosInstance.get(url + '/creator/dashboard/connected_accounts/youtube', {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {
        console.log("TEST: ", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        resolve(error);
      });
  });

}


export function updateYoutubeAccount({socialMediaAccountId,nicheId,audienceAge,rate}:any){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axios.post(url+'/youtube/update_youtube_account',{
        socialMediaAccountId: socialMediaAccountId,
        nicheId: nicheId,
        audienceAge: audienceAge,
        rate: rate
      },{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
        resolve(error);
        });
      });
}





