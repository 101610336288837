import { useState, useEffect } from "react";
import { getCampaignVideoDetail, getYouTubeVideoId } from "./controller.campaign";
import { useParams, useLocation } from "react-router-dom";

interface CampaignOrder {
  id: number;
  creatorId: number;
  campaignId: number;
  socialMediaId: number;
  nicheId: number;
  videoUrl: string;
  viewCount: number | null;
  commentCount: number | null;
  likeCount: number | null;
}

export default function CampaignOrderDetail(order: any) {
  const [loadingVideoDetail, setLoadingVideoDetail] = useState<boolean>(false);
  const [campaignVideoDetail, setCampaignVideoDetail] = useState<CampaignOrder>();
  const [videoUrl, setVideoUrl] = useState<string>("");
  const param = useParams();
  const stateData = useLocation();

  useEffect(() => {
    const creatorId: number = stateData.state.creatorId;
    const campaignId: number = parseInt(param.id ?? "0");

    getCampaignVideoDetail(creatorId, campaignId)
      .then((res: any) => {
        console.log("RRRRRRRRR: ", res);
        setCampaignVideoDetail(res);
        var embedUrl: string = getYouTubeVideoId(res?.videoUrl);
        setVideoUrl(embedUrl);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  return (
    <div className="singleOrder">
      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.viewCount ?? "NA"}</h3>
          <p>Current View</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.likeCount ?? "NA"}</h3>
          <p>Like Count</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.commentCount ?? "NA"}</h3>
          <p>Comment Count</p>
        </>
      </div>

      <div className="singleOrder">
        {
          videoUrl?.includes("youtube") ?
          <iframe
            width="83%"
            height="615"
            src={videoUrl? videoUrl.replace("http://","https://"): ""}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

            allowFullScreen
          ></iframe>
          :
          <iframe
          width="83%"
          height="615"
          src={videoUrl? videoUrl.replace("http://","https://") : ""}
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

          allowFullScreen
        ></iframe>
        }
       
      </div>
    </div>
  );
}
