import { DataGrid } from '@mui/x-data-grid';
import { tableProp } from './prop.table';

export default function Table(dataProp: tableProp) {
  { console.log(dataProp.data.length)}
  return (
    
    <div style={{  width: '100%' , overflowX: 'scroll'}}>
    
         {dataProp.data.length === 0 ? (
        // <div style={{marginTop:"20px"}}>No data available </div>
        <div style={{ width: '100%', overflowX: 'scroll' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
          <thead>
            <tr>
              {dataProp.columns.map((column) => (
                <th key={column.field} style={{fontWeight:"normal", padding:5, fontSize:"15px"}} >
                  {column.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={dataProp.columns.length} style={{ textAlign: 'center', marginTop: '20px', border: '1px solid #ddd', padding: '50px' }}>
                No data available
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      ) : (
    
      <DataGrid
        rows={dataProp.data}
        
        sx={{
          '& .MuiDataGrid-cell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '20px',

          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'white',
            color: '#090d25',
            fontSize: '1rem',
            textAlign:'center',
            display:'flex',
            flexDirection:'row',
            paddingLeft: '20px',
          },
          '& .headerCell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center', // Ensure the text inside the header cell is centered
          },
        }}
        columns={dataProp.columns.map((column) => ({
          ...column,
          headerClassName: 'headerCell', // Apply the custom class to the header cells
        }))}
     
        initialState={{
         
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        density='standard'
      />
      )}
    </div>
  );
}