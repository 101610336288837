import { useFormik } from "formik";
import * as Yup from "yup";
const BusinessSigninSchema = Yup.object().shape({
    username: Yup.string().required("Username Required"),
    password: Yup.string().required("Password Required"),
    businessName: Yup.string().required("Business Name Required"),
    tinNumber: Yup.string().required("Tin Number Required"),
    tinCirtificate: Yup.mixed()
    .required('TIN Certificate is required')
    .test(
      'fileType',
      'Only image files with extensions jpg, jpeg, png are allowed',
      (value) => {
        if (!value) return false; // Reject if no file is provided
        const file = value as File;
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileName = file.name || ''; // Ensure fileName is a string
        const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? ''; // Ensure fileExtension is a string
        return allowedExtensions.includes(fileExtension);
      }
    ),
    licenseNumber: Yup.string().required("License number Required"),
    licenseCertificate: Yup.mixed()
    .required('License Certificate is required')
    .test(
      'fileType',
      'Only image files with extensions jpg, jpeg, png are allowed',
      (value) => {
        if (!value) return false; // Reject if no file is provided
        const file = value as File;
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileName = file.name || ''; // Ensure fileName is a string
        const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? ''; // Ensure fileExtension is a string
        return allowedExtensions.includes(fileExtension);
      }
    ),
    vatNumber: Yup.string().required("Vat number Required"),
    VatCertificate: Yup.mixed()
    .required('VAT Certificate is required')
    .test(
      'fileType',
      'Only image files with extensions jpg, jpeg, png are allowed',
      (value) => {
        if (!value) return false; // Reject if no file is provided
        const file = value as File;
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileName = file.name || ''; // Ensure fileName is a string
        const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? ''; // Ensure fileExtension is a string
        return allowedExtensions.includes(fileExtension);
      }
    ),
    phone: Yup.string().required("Phone Number is Required"),
    email: Yup.string().email().required("Email is Required"),
    address: Yup.string().required("Phone Number is Required"),


});



export const businessSignupFormik = {
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: { username: "", password: "", businessName: "", tinNumber: "", tinCirtificate: "", licenseNumber: "", licenseCertificate: "", vatNumber: "", VatCertificate: "", phone: "", email: "", address: "" },
    validationSchema: BusinessSigninSchema,

};

