import './App.css';
import MainScreen from './screens/main/mainScreen';
import axiosInstance from './axiosConfig'; 

function App() {
  return (
    <div className="App">
   <MainScreen />
    </div>
  );
}

export default App;
