
import { useNavigate } from "react-router-dom";
import "./style.login.css";

export default function Login(){

    const navigation = useNavigate();
    return(
        <div className="login">
          
          <div className="loginScreenBox1">

            <button 
            onClick={()=>{
                navigation("/creator_login");
            }
            }
            >
                Login As Creator
            </button>

          </div>

          <div className="loginScreenBox">

            <button    onClick={()=>{
                navigation("/business_login");
            }
            }>    
                Login As Business
            </button>

            </div>
        </div>
    );
}