// CampaignDescription.tsx
import React from 'react';

interface Props {
  description: string;
  setDescription: (value: string) => void;
  selectedDate: string;
  setSelectedDate: (value: string) => void;

}

const CampaignDescription: React.FC<Props> = ({ description, setDescription, selectedDate, setSelectedDate }) => {
  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div className="fadeIn">
      <p className='question'>  Campaign Description</p>
      <div className="flex flex-col items-center justify-center ">
        <textarea
          className="w-[100%] h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-blue-500"
          placeholder="Enter campaign description here..."
          //    value={text}
          onChange={(event: any) => {
            setDescription(event.target.value);
          }}
        />


      </div>


      <div className="flex flex-col my-[3%]">
        <p className='question'>  Select start Date </p>
        <input
          type="date"
          id="datepicker"
          min={new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
          className="w-64 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>


    </div>
  );
};

export default CampaignDescription;
