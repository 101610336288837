import { Link, useParams } from "react-router-dom";
import DashboardHome from "./home/homeBusiness";
import "../dashboard/style.mainDashboard.css";
import "../dashboard/style.mobile.mainDashboard.css";
import logo from "../../component/assets/images/zeter adv white logo1.png";
import { AiFillHome, AiOutlineMenu, AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineOrderedList } from "react-icons/ai";
import { RiAccountCircleFill, RiOrderPlayFill, RiQuestionAnswerFill, RiQuestionFill, RiQuestionMark, RiShoppingBag2Fill } from "react-icons/ri";
import { CiMenuKebab } from 'react-icons/ci'
import { useEffect, useState } from "react";
import { mainDashboardProp } from "../dashboard/prop.mainDashboard";

import { userProp } from "../auth/prop.creator";
import { getUserInfo } from "../../util/controller";
import CreateCampaign from "./createCampaign/createCampaign";
import ListCampaign from "./campaign/listCampaigns";
import DetailCampaign from "./campaign/campaignDetail";
import CampaignOrderDetail from "./campaign/campaignOrderDetail";
import BusinessProfile from "./profile/business_profile";
import CreateCampaignNew from "./createCampaign/createCampaignNew";
import Faq from "./faq/faq";
import Markating from "./markating/markating";
import PublicCreatorPage from "./public_creator_profile/publicCreatorPage.";

export default function MainBusinessDashboard(screen: mainDashboardProp) {
  //const [title, setTitle] = useState<string>("home");
  //  const [screenS, setScreenS] = useState<string>(screen.screen);
  const [bodyInfo] = useState<userProp>(getUserInfo());
  // const [campaignId, setCampaignId] = useState<number>(0);
  const [headerMenuEnabled,setHeadeMenuEnabled] = useState<boolean>(false);

  const { id }: any = useParams();

  useEffect(() => {
    //setCampaignId(id);
  }, []);

  return (
    <div className={"mainDashboardScreen"}>
      <div className={"leftNav"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <a href="/dashboard/business/home"> 
          <img
            alt="img"
            src={logo}
            width="90px"
            style={{ margin: 50, width: "60%" }}
          />
          </a>
          {/* <h2>Zeter</h2> */}
        </div>

        <ul>
          <li>
            <Link
              className={
                screen.screen === "home" ? "navButton-selected" : "navButton"
              }
              to={"/dashboard/business/home"}
            >
              <AiFillHome
                className={
                  screen.screen === "home"
                    ? "iconButtons-selected"
                    : "iconButtons"
                }
              />
              <p>Home</p>
            </Link>
          </li>

          <li>
            {" "}
            <Link
              onClick={() => {
                // setTitle("dsf");
              }}
              className={
                screen.screen === "listCampaign"
                  ? "navButton-selected"
                  : "navButton"
              }
              to={"/dashboard/business/listCampaign"}
            >
              <RiOrderPlayFill
                className={
                  screen.screen === "order"
                    ? "iconButtons-selected"
                    : "iconButtons"
                }
              />
              <p> Orders</p>
            </Link>
          </li>
          <li>
            <Link
              className={
                screen.screen === "profile" ? "navButton-selected" : "navButton"
              }
              to={"/dashboard/business/profile"}
            >
              <RiAccountCircleFill
                className={
                  screen.screen === "profile"
                    ? "iconButtons-selected"
                    : "iconButtons"
                }
              />
              <p> Profile</p>
            </Link>
          </li>
          <li>
            <Link
              className={
                screen.screen === "faq" ? "navButton-selected" : "navButton"
              }
              to={"/dashboard/business/faq"}
            >
              <RiQuestionFill
                className={
                  screen.screen === "faq"
                    ? "iconButtons-selected"
                    : "iconButtons"
                }
              />
              <p> FAQ</p>
            </Link>
          </li>
          <li>
            <Link
              className={
                screen.screen === "marketing" ? "navButton-selected" : "navButton"
              }
              to={"/dashboard/business/marketing"}
            >
              <RiShoppingBag2Fill
                className={
                  screen.screen === "marketing"
                    ? "iconButtons-selected"
                    : "iconButtons"
                }
              />
              <p> Marketing Steragies</p>
            </Link>
          </li>
          {/* <li style={{border: '1px solid #A3AED0',borderRadius: 5,width:'80%',alignSelf:'center'}}>

                <h1 style={{color:'#A3AED0',fontWeight:'bold',fontSize:24}}>1000,000,000+ </h1>
                <h1 style={{color: '#A3AED0',fontWeight:'bold',fontSize:24}}> Views </h1>

          </li> */}
        </ul>
      </div>
      <div className="mobileFooter">
        <Link
          to={"/dashboard/business/home"}
          className={
            screen.screen === "home" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiFillHome />
        </Link>

        <Link
          to={"/dashboard/business/listCampaign"}
          className={
            screen.screen === "listCampaign" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiOutlineOrderedList />
        </Link>

        <Link
          to={"/dashboard/business/profile"}
          className={
            screen.screen === "profile" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <RiAccountCircleFill />
        </Link>
      </div>
      <div className={"headerTool"}>
        <div className="headerLeft">
          <p>{screen.nav}</p>
          <h2>{screen.headerText}</h2>
        </div>

        <div className="headerRight">
          <p>{bodyInfo.firstName + " " + bodyInfo.lastName}</p>
          <Link to="/dashboard/business/profile">   
          
          </Link>
          <span onClick={() => { 
          setHeadeMenuEnabled(!headerMenuEnabled);
        }} style={{width: 40, height: 40, borderRadius: 20,alignItems: 'center',flexDirection:'row', display:'flex',justifyContent: 'center',margin: 5, backgroundColor: '#090d25',color:'white'}}> {bodyInfo.firstName.charAt(0)} </span>
       
        
        
        </div>
        {headerMenuEnabled && 
        <div style={{position: 'fixed',top: 66,right: 20,zIndex:1000, width: 160, height:60,justifyContent:'center',alignItems:'center', boxShadow: '2px -2px 2px lightgray',border:'1px solid #090d25', borderRadius: 5, backgroundColor:'white'}}>
          <ul>
          
          <li onClick={()=>{
              localStorage.removeItem("token");
              localStorage.removeItem("userInfo");
  
              window.location.href = "https://zeterads.com/";
          }} style={{width: '80%', cursor: 'pointer',backgroundColor: '#090d25',padding: 6, borderRadius: 5, margin: 'auto',marginTop:14}}>Logout</li>

            </ul>
        </div>
        }
        {/* <div className="headerRight">
          <p>{bodyInfo.firstName + " " + bodyInfo.lastName}</p>
          <img alt="img" src={bodyInfo.profilePicture} />
        </div> */}
      </div>
      <div className={"dashboardContent"}>
        {screen.screen === "home" ? (
          <DashboardHome />
        ) : screen.screen === "campaign" ? (
          <CreateCampaign />
        ) :  screen.screen === "campaign-new" ? (
          <CreateCampaignNew />
         ): screen.screen === "listCampaign" ? (
          <ListCampaign />
        ) : screen.screen === "DetailCampaign" ? (
          <DetailCampaign id={id} />
        ) : screen.screen === "CampaignOrderDetail" ? (
          <CampaignOrderDetail order={id} />
        ) :  screen.screen === "profile" ? (
          <BusinessProfile  />
        ) : screen.screen === "faq" ? (
          <Faq />
        ) : screen.screen === "marketing" ? (
          <Markating /> 
        ): screen.screen === "public_creator" ? (
          <PublicCreatorPage />
        ): null}
      </div>
    </div>
  );
}
