import Footer from "../footer/footer";
import Header from "../header/header";

export default function Pricing() {
  return (
    <>
      <Header title="pricing" />
      <div className=" bg-[#121449] pb-10">
        <section className="py-6 leading-7 text-gray-900  sm:py-12 md:py-16 w-[80%] m-auto">
          <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">
            <div className="flex flex-col items-start leading-7 text-start text-gray-900 border-0 border-gray-200 ">
              <h3
                id="pricing"
                className="box-border m-0 text-sm  leading-tight tracking-tight text-white border-solid sm:text-sm md:text-lg"
              >
               Plans page 

Zeter is committed to simplifying and optimizing the advertising process. We firmly believe that a payment structure based on per 1000 views is equitable for both creators and businesses. This approach not only ensures fairness but also mitigates the risk of overpayment and potential scams. By establishing a secure, uncomplicated, and efficient advertising process, we empower creators and businesses with the freedom to exercise control over their budgets, marketing targets, and more.
<br />
<br />
To meet the needs for our diverse clientele, Zeter has carefully crafted three distinct plans, each offering unique perks tailored to meet varying needs. Explore the details of these plans below to discover the perfect fit for your advertising requirements.
product placement 
add marketing consulting in the premium plan
<br />
<br />
 
Standard plan  easy marketing for everyone 
<br />
<br />
<h1  style={{  marginTop: 20, fontSize: "1.6em", fontWeight: 700,fontFamily:'Railway' }}>
<span style={{ color: "#6968FF", marginTop: 20,  fontWeight: 700,fontFamily:'Railway' }}> 
Our Standard Plan
  </span> makes marketing easy! Here's how it works:
 
</h1>
<br />
Our Standard Plan makes marketing easy! Here's how it works:

<br />
<br />


1. Choose Your Niche: Pick the type of audience you want to reach.
<br />
<br />

2. Set Your Views: Tell us how many views you want for your campaign.
<br />
<br />

3. Add Details: Provide a few more details about your campaign.
<br />
<br />



Our smart algorithm will then find the best creators for your campaign. Once the creators are chosen and approved, they will post your campaign everywhere. It’s that simple!
<br />
<br />

<h1  style={{  marginTop: 20,  fontSize: "1.6em", fontWeight: 700 }}>
<span style={{ color: "#6968FF", marginTop: 20,  fontWeight: 700 }}> 
Zeter plus, 
  </span>your favorite creators ,your way 
</h1>
<br />
<br />

With Zeter Plus, you can choose your favorite creator and create a campaign just the way you want. Here’s how it works:

<br />
<br />

1. Pick Your Creator: Select the creator you love.
2. Create Your Campaign: Decide if you want a simple mention of your brand or a whole segment dedicated to it.
3. Control the Details: Set all the details of your marketing campaign.
<br />
<br />

This way, you can make sure your brand is represented by someone who matches your style, making your favorite creator the face of your brand.





              </h3>
              <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl"></p>
            </div>

           
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
