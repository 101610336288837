import { useState } from "react";
import { formBuilder } from "../prop.form";
import "../style.form.css";

export default function CTextArea(textInputInfo: formBuilder) {
  const [update, setUpdate] = useState<string>(textInputInfo.value);
  return (
    <div className="cTextInput">
      <label>
        {textInputInfo.label}

        {textInputInfo.required && (
          <span className="text-red-600 text-sm align-middle"> * </span>
        )}
      </label>
      <textarea
        value={update}
        placeholder={textInputInfo.placeholder}
        onChange={(text) => {
          textInputInfo.onChange(text.target.value);
          setUpdate(text.target.value);
        }}
      />
    </div>
  );
}
