import { useMemo } from "react";
import "./style.payment.css";
import Table from "../../../component/table/table";
import { useEffect,useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getRows } from "./controller.payment";
import { CircularProgress } from "@mui/material";

export default function Payments(){

      const [data,setData] = useState<any>([]);
      const [columns,setColumns] = useState<GridColDef[]>([]);
      const [loading,setLoading] = useState<boolean>(false);
      useEffect(()=>{
        setLoading(true);
          setColumns(getColumns());
          async function getRowsAsync(){
            var rowDataList = await getRows();
            console.log("rowdata",rowDataList);
              setData(rowDataList);
            setLoading(false);

          }
          getRowsAsync();
      },[])
    
    return (
        <div className="orderScreen">
            {loading ? <CircularProgress /> : 
            <Table columns={columns} data={data} />
    }
        </div>
    );
}