

export default function WaitingForBusiness(){

    return (
        <div style={{padding: 10}}>
            <h1>Waiting for Business</h1>
            <p> You will be notified when the buisness approves your acceptance</p>
       <img src="https://img.freepik.com/premium-photo/timeout-2d-cartoon-vector-illustration-white-backgroun_889056-29175.jpg"
         style={{width: 300,height: 300, margin: 'auto',filter: 'grayscale(0%)'}}/>
         {/* filter: 'grayscale(100%)' */}
        </div>
    );
}