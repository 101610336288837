// src/axiosConfig.js
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create();
console.log("Error: ", "403 error intercepted3");

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403 || error.response.status === 500) {
      console.log("403 error intercepted");
      // Dispatch an event for a 403 error
      const event = new CustomEvent('axios403', { detail: error.response });
      window.dispatchEvent(event);
    }else{
      console.log("Error: ", "403 error intercepted2");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
