

import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./style.google_oauth.css";

import { BiLogoYoutube } from "react-icons/bi";
import { useEffect } from "react";
import { getRefershToken } from "./controller.google_oauth";
export default function GoogleAuth(){
    const navigator = useNavigate();
    
    useEffect(() => {
        const url = new URL(window.location.href);
        
        // Get values from query parameters
        const code = url.searchParams.get('code');
        if(code){
            getRefershToken(code).then((res: any)=>{
                
                //navigator("http://127.0.0.1:3000/dashboard/profile");
                // since the navigator is not working so i am using window.location.href
                // check if the website is running on local or on server
                    if(window.location.href.includes("127.0.0.1")){
                        window.location.href = "http://127.0.0.1:3000/dashboard/profile";
                    }else{

                        window.location.href = "https://zeterads.com/dashboard/profile";
                    }
            }).then((err: any)=>{
                console.log(err)
                //alert(JSON.stringify(err));
            });

           
        }else{  

           // navigator("http://127.0.0.1:3000/dashboard/profile");
           window.location.href = "https://zeterads.com/dashboard/profile";
            
        }
    
    },[])
    return(
        <div className="googleAuth">

                <div className="middleContaint">
                    <div className="middleHeader"> 
                    <BiLogoYoutube className="iconMiddleHeader" />
                <h1> Successfully Logedin to Youtube </h1>
                </div>
                <Link to="/dashboard/profile" >
                <button className="primaryButton"> Continue To Dashboard </button>
                </Link>
                </div>

        </div>
    );
}