// NicheSelection.tsx
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../../../../util/constant';
import axiosInstance from '../../../../axiosConfig';

interface Props {
    nichList: any[];
    selectedNich: number | undefined;
    setSelectedNich: (value: number) => void;
    megaNich: string;
}

const NicheSelection: React.FC<Props> = ({ nichList, selectedNich, setSelectedNich, megaNich }) => {
    const handleRadioChange = (id: number) => {
        
        setSelectedNich(id);
    };
    const [searchNich, setSearchNich] = useState<string>('');
    const [nichListLocal, setNichList] = useState<any[]>(nichList);

    useEffect(() => {
        let megaNichId = 1;
        if (megaNich == "entertainment") {
            megaNichId = 1
        }else if(megaNich == "education"){
            megaNichId = 2;
        }else if(megaNich == "news"){
            megaNichId = 3; 
        }

        axiosInstance.get(`${url}/creator/search_niches/ALL/mega_niche/${megaNichId}`).then((res) => {

            setNichList(res.data);
            
        }
        ).catch((err) => {
            console.log(err)
        })

    }, []);

    useEffect(() => {
        let megaNichId = 1;
        if (megaNich == "entertainment") {
            megaNichId = 1
        }
        axiosInstance.get(`${url}/creator/search_niches/${searchNich}/mega_niche/${megaNichId}`).then((res) => {

            setNichList(res.data);
            
        }
        ).catch((err) => {
            console.log(err)
        })

    }, [searchNich]);

    return (
        <div className="fadeIn">
            <div>
                <p className='question'> Select Specific Niche </p>
                <p style={{textAlign: 'start', color:'gray', fontSize: 17}}>Your selected Niche will be given more priority, however we will also be using related Niche if the amount of view can not be satisfied,Please click <a style={{color: "#2D2CAB"}} href="/dashboard/business/campaign-new" > Here </a> for more info  </p>

                <div className="radio-container">
                    <input onChange={(text: any) => {

                        setSearchNich(text.target.value.toString());
                    }} type='text' placeholder='search niche...' />
                </div>


                    <div className='radio-parent'> 

                {
                    nichListLocal.map((niche: any) => {

                        const nicheLabel = niche.Name ?? niche.name;
                        const nicheId = nicheLabel ?? `niche-${niche.id}`;

                        return (
                            <div className="radio-container-single">
                                <input
                                    type="radio"
                                    id={nicheId}
                                    name="options"
                                    value={niche.Name ? niche.Name : niche.name}
                                    
                                    checked={nicheId === selectedNich}
                                    onChange={() => handleRadioChange(nicheId)}
                                />
                                <label htmlFor={niche.Name ? niche.Name : niche.name}>{niche.Name ? niche.Name : niche.name}</label>
                            </div>
                        )
                    })
                }
                </div>


            </div>
        </div>
    );
};

export default NicheSelection;
