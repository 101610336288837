export const messageContent = [
    {
      "sender": "user",
      "text": "Hey there!"
    },
    {
      "sender": "bot",
      "text": "Hello! How can I help you today?"
    },
    {
      "sender": "user",
      "text": "Can you tell me a joke?"
    },
    {
      "sender": "bot",
      "text": "Why don't scientists trust atoms? Because they make up everything!"
    }
  ]
  