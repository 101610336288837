import { useFormik } from "formik";
import * as Yup from "yup";
const SigninSchema = Yup.object().shape({
  username: Yup.string().required("Username Required"),
  password: Yup.string().required("Password Required"),
});

export const loginFormik = {
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: { username: "", password: "" },
  validationSchema: SigninSchema,
 
};

