import { useState } from "react";
import { formBuilder } from "../prop.form";
import "../style.form.css";

export default function CTextInput(textInputInfo: formBuilder) {
  const [update, setUpdate] = useState<string>(textInputInfo.value);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  return (
    <div className="cTextInput">
      <label>
        {textInputInfo.label}
        {textInputInfo.required && <span className="text-red-600 text-sm align-middle"> * </span>}
      </label>

      <input
        className={
          textInputInfo.name === "password" ? "passwordField" : "textInput"
        }
        type={
          textInputInfo.name === "password"
            ? isPasswordVisible
              ? "text"
              : "password"
            : "text"
        }
        value={update}
        placeholder={textInputInfo.placeholder}
        onChange={(text) => {
          textInputInfo.onChange(text.target.value);
          setUpdate(text.target.value);
        }}
       
      />
      {textInputInfo.name === "password" && (
        <span
          style={{
            position: "absolute",
            right: "20px",
            top: "45px",
            justifySelf: "center",
            alignSelf: "center",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? "👁️" : "👁️‍🗨️"}
        </span>
      )}
    </div>
  );
}
