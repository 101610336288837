
import { useState } from "react";
import "./style.faq.css";


export default function Faq(){
    const [selected_faqs, setSelectedFaqs] = useState<number>(0);
    let faq_sections = [
        {
            "title": "General",
            "content": [
                {
                    "question": "What is Zeter?",
                    "answer": "Zeter is your gateway to connecting with passionate influencers in Ethiopia, helping your brand shine and thrive effortlessly. Zeter envisions being the go-to advertising platform, providing a comforting and smooth experience where businesses and brands can thrive and reach their full potential."
                },
                {
                    "question": "How does Zeter work?",
                    "answer": "Zeter works by helping businesses promote their ads through local content creators and their social media networks."
                },
                {
                    "question": "How does Zeter Standard work?",
                    "answer": "When you request a campaign in the standard plan, our algorithm selects content creators based on the preferences you set while creating the campaign. Once these creators accept the campaign, your advertisement is posted and goes live. The content the influencer posted can be tracked in the details page."
                },
                {
                    "question": "How does Zeter Plus work?",
                    "answer": "With Zeter Plus, your business selects specific creators and sets detailed requirements. After you and the creators agree on the rate and they accept the campaign requirements, your campaign goes live and starts running."
                }
            ]
        },
        {
            "title": "Payments",
            "content": [
                {
                    "question": "How do I pay?",
                    "answer": "You can pay using Telebirr, Abyssinia Bank, and Commercial Bank of Ethiopia. 1. Transfer the amount using the recommended bank accounts. 2. Put the transaction number into the space provided. 3. Wait for confirmation from our staff. 4. Once confirmed, you can continue to the details page."
                },
                {
                    "question": "How do I get paid?",
                    "answer": "You will be paid by one of the bank accounts you provided in the profile section."
                },
                {
                    "question": "What is the confirmation fee?",
                    "answer": "The confirmation fee is there to lock your campaign into the system for creators to evaluate and accept/reject your campaign."
                },
                {
                    "question": "What is the pay-per-view system?",
                    "answer": "The pay-per-view system is a pricing model where you pay based on the number of times your content, such as an ad, is viewed. Instead of paying a flat fee, you only pay for each individual view or interaction, ensuring you're only charged for actual engagement."
                }
            ]
        },
        {
            "title": "Campaign",
            "content": [
                {
                    "question": "What is a campaign?",
                    "answer": "A campaign is an organized distribution of advertising media through the Zeter system."
                },
                {
                    "question": "How much time does a campaign take?",
                    "answer": "A campaign takes 1-2 weeks to be completed."
                },
                {
                    "question": "How do I choose a creator?",
                    "answer": "Use the search option in the Zeter Plus plan to search for your favorite influencer."
                },
                {
                    "question": "How do I know the campaign is fully done?",
                    "answer": "The campaign is fully done when the 2 weeks period is over and/or the campaign achieves the set amount of goals."
                }
            ]
        },
        {
            "title": "Refunds",
            "content": [
                {
                    "question": "Can I get a refund?",
                    "answer": "If you have any disputes relating to payment, please contact the support team."
                }
            ]
        }
    ];

    return (
        <div className="container">
        <h1>FAQ</h1>
        <div className="faq-sections">
        {faq_sections.map((section, index) => (
            <div onClick={()=>{
                setSelectedFaqs(index);
            }} key={index} className={index == selected_faqs ? "faq-header-section-selected" : "faq-header-section"} >
            <h2>{section.title}</h2>
            </div>
        ))}
        </div>
        <div className="faq-content">
        {faq_sections[selected_faqs].content.map((faq, index) => (
            <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
            </div>
        ))}
        </div>
        </div>
    )
}