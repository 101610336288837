// CreateCampaignNew.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfettiExplosion from 'react-confetti-explosion';
import SocialMediaSelection from './components/socialMediaSection';
import PlanSelection from './components/planSection';
import NicheSelection from './components/nicheSection';
import MediaUpload from './components/mediaUpload';
import DateSelection from './components/dateSection';
import CampaignDescription from './components/campaignDescription';
import { url } from '../../../util/constant';
import MegaNichSelection from './components/megaNichSection';
import ExpectedView from './components/expectedView';
import SelectedCreators from './components/selectedCreators';
import WebsiteCollector from './components/WebsiteCollector';
import ErrorComponent, { ErrorComponentProps } from './components/ErrorComponent';
import PaymentSection from './components/PaymentSection';
import { useNavigate, useNavigation } from 'react-router-dom';
import { fileUpload } from '../../create_account/controller.create_account';
import axiosInstance from '../../../axiosConfig';
import { SearchCreators } from './components/searchCreators';
import congraImage from '../../../component/assets/cong.png';
import { Loading } from '../../../component/loading/loading';

const CreateCampaignNew: React.FC = () => {
  const [pages, setPages] = useState<number>(1);
  const [socialMedia, setSocialMedia] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const [megaNich, setMegaNich] = useState<string>('');
  const [nichList, setNichList] = useState<any[]>([]);
  const [selectedNich, setSelectedNich] = useState<number>(0);
  const [fileObjects, setFileObjects] = useState<any[]>([]);
  const [videoLength, setVideoLength] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isExploding, setIsExploding] = useState<boolean>(false);
  const [expectedView, setExpectedView] = useState<string>('');
  const [selectedCreatorList, setSelectedCreatorList] = useState<any[]>([]);
  const [selectedCreator, setSelectedCreator] = useState<any[]>([]);
  const [loadingCreators, setLoadingCreators] = useState<boolean>(false);
  const [loading,setLoading] = useState<boolean>(false);
  const [errorObject, setErrorObject] = useState<ErrorComponentProps>();
  const [paymentInfo,setPaymentInfo] = useState<any>();
  const [campaignPhoto, setCampaignPhoto] = useState<string>('');
  const [campaignId, setCampaignId] = useState<number>(0);
  const [selectedCreatorListPlus,setSelectedCreatorListPlus] = useState<any[]>([]);
  const navigation = useNavigate();




  useEffect(()=>{
    let campaignBody = getCampaignBody(selectedCreatorListPlus);
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
    setLoadingCreators(true);
    axios.post(`${url}/business/campaign/create/new`, campaignBody,{
      headers: {
          "Authorization": "Bearer " + userInfo
      }
  } ).then((res) => {
      console.log("Campaign created: ", res.data)
      let arrayCreatorList = convertDictToArray(res.data.creatorList);
      setSelectedCreatorList(arrayCreatorList);
      setLoadingCreators(false);
    setPages(pages + 1);

      setCampaignId(res.data.campaignId);

    }).catch((err) => {
      console.log(err)
    });
  },[selectedCreatorListPlus])


  const [websiteList,setWebsiteList] = useState<any[]>([{
    "type": "social_media",
    "website": "YouTube",
    "url": ""
  }]);
  const shouldRenderConfetti = true;

  const handleConfettiComplete = () => {
    setIsExploding(false);
  };

  const getCampaignBody = (selectedCreators: any[] = []) => {
    return {
      socialMediaId: socialMedia == "youtube" ? 1 : socialMedia == "tiktok" ? 2 : 3, 
      planId: plan == "standard" ? 1 : plan == "plus" ? 2 : 3,
      nichId: selectedNich != 21 ? 21 : selectedNich,
      videoUrl: campaignPhoto,
      photoUrl: campaignPhoto,
      expectedViews: expectedView.split(",").join(""),
      videoLength,
      startDate: selectedDate,
      selectedCreatorList: selectedCreators,
      description,
    };
  }

  // const navigation = useNavigate();

  function convertDictToArray(data: any) {
    const result = [];
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            value.key = key;
            result.push(value);
        }
    }
    return result;
}

  useEffect(() => {
    let megaNichId = 4;
    if (megaNich == "entertainment") {
      megaNichId = 4
    }
    axiosInstance.get(`${url}/creator/get_nich_by_mega_nich/${megaNichId}`).then((res) => {
      console.log("NICH LIST: ", res)
      setNichList(res.data)
    }
    ).catch((err) => {
      console.log(err)
    })
  }, [megaNich])


  const renderCurrentPage = () => {
    return pages === 1 ? (
      <SocialMediaSelection socialMedia={socialMedia} setSocialMedia={setSocialMedia} setPages={setPages} />
    ) : pages === 2 ? (
      <PlanSelection plan={plan} setPlan={setPlan} setPages={setPages} />
    ) : pages === 3 && (plan == "standard" ) ? (
      <MegaNichSelection niche={megaNich} setMegaNich={setMegaNich} setPages={setPages} />
    ) : pages === 4 && (plan == "standard" ) ? (
      <NicheSelection nichList={nichList} selectedNich={selectedNich} setSelectedNich={setSelectedNich} megaNich={megaNich} />
    ) : (pages === 5 && plan == "standard" ) || ( pages === 3 && plan == "plus") ? ( 
      <MediaUpload fileObjects={fileObjects} setFileObjects={setFileObjects} setVideoLength={setVideoLength} socialMedia={socialMedia} setCampaignPhoto={setCampaignPhoto} setFileLoading={setLoading} />
    ) : (pages === 6 && plan == "standard" ) || ( pages === 4 && plan == "plus") ? (
      <CampaignDescription selectedDate={selectedDate} setSelectedDate={setSelectedDate} description={description} setDescription={setDescription} />
    )  : (pages === 7 && plan == "standard" ) || ( pages === 5 && plan == "plus")  ? (
      <ExpectedView expectedView={expectedView} setExpectedView={setExpectedView} />
    ) : (pages == 8 && plan == "standard" ) || ( pages === 6 && plan == "plus")  ? (
      <WebsiteCollector WebsiteList={websiteList} setWebsiteList={setWebsiteList} setPages={setPages} />
    ):
     pages === 9 && plan == "standard" ? (
      <SelectedCreators creators={selectedCreatorList} setErrorObject={setErrorObject} setSelectedCreatorList={setSelectedCreatorList} setPages={setPages} campaignId={campaignId} loading={loadingCreators} setLoading={setLoadingCreators} nichId={selectedNich} />
    ) : (pages === 10 && plan == "standard" ) || ( pages === 8 && plan == "plus") ?
    ( <PaymentSection  setPaymentInfo={setPaymentInfo} campaignId={campaignId} /> ) : 
      pages === 7 && plan == "plus" ? (
      <SearchCreators  setSelectedCreatorList={setSelectedCreatorListPlus} socialMedia={socialMedia == "telegram"? 3 : 1} />
    )
    :null

  };

  return (
    <div>
      
      <div className='campaignContainer'>
        {renderCurrentPage()}

        {loading && <Loading loading={loading} setLoading={setLoading} /> }

        {errorObject && 
        <ErrorComponent {...errorObject} />
        }  
           {pages == 11 && (
            <>
        <ConfettiExplosion onComplete={handleConfettiComplete} />
        <div className="confettiText" style={{width:'100%', display: 'flex', flexDirection:'column',justifyContent: 'center', alignItems:'center'}}>
            <h1>Campaign Created Successfully</h1>
            <img src={congraImage} alt="celebration" width={400} height={400} />

        </div>
        <button className="bottomButtons" onClick={() => {
          navigation("/dashboard/business/campaignDetail/"+campaignId);
        }}>Let's Go</button>
        </>
      )}

        {/* { true &&
          <PaymentSection />
        } */}

        <div className="navigateButtons">
          {  (pages <= 7 && plan == "plus" ) && 
          <button className="bottomButtons" onClick={() => setPages(pages - 1)}>Back</button>
          }
          {(pages > 1 && pages < 9 && plan == "standard") &&
          <button className="bottomButtons" onClick={() => setPages(pages - 1)}>Back</button>
          }

          {
           ((pages > 4 && plan == "standard") ||
              (pages < 8 && plan == "standard") || 
              (pages == 9 && plan == "standard"))
              && <button className="bottomButtons" onClick={() => {
            
                let nextPage_is_valid = true;
                if(pages == 4 && selectedNich == undefined){
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "You Must Select a niche to continuee",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  
                  })         
                }else if(pages == 4 && selectedNich != undefined){
                  nextPage_is_valid = true;
                }
                if(pages == 5 && socialMedia == "telegram"){
                   if(campaignPhoto){
                    nextPage_is_valid = true;
                  } else{
                    setErrorObject({
                      errorTitle: "warning",
                      errorMessage: "You Must Select an image file from your device to continuee, please select an image file and try again HHH: " + campaignPhoto,
                      posetiveAction: () => {
                        setErrorObject(undefined);
                      },
                      negativeAction: () => {
                        setErrorObject(undefined);
      
                      },
                      type: "warning",
                      posetiveText: "Ok",
                      negativeText: "Cancel"
                    })
    
                  }
                }else if(pages == 5 && fileObjects.length == 0){
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "You Must Select a file from your device to continuee, please select a file and try again. the video file must be less than 45 Seconds",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  })
                } else if(pages == 5 && fileObjects.length > 0 && fileObjects[0].duration <= 45){
                  nextPage_is_valid = true;
                } else if(pages == 5 && fileObjects.length > 0 && fileObjects[0].duration > 45){
    
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "The video file you selected is longer than 45 seconds, please select a file that is less than 45 seconds",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  })
    
                }else if (pages == 6 && selectedDate == "" && description == ""){
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "You Must Select a Start Date and add a description to continuee",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  })
                }else if(pages == 6 && selectedDate != "" && description != ""){
                  nextPage_is_valid = true;
                }else if(pages == 7 && expectedView == ""){
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "You Must add an expected view count to continuee",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  })
                }else if(pages == 7 && expectedView != "" && parseInt(expectedView.replaceAll(",","")) < 1000){
                  setErrorObject({
                    errorTitle: "warning",
                    errorMessage: "The expected view count must be greater than 1000",
                    posetiveAction: () => {
                      setErrorObject(undefined);
                    },
                    negativeAction: () => {
                      setErrorObject(undefined);
    
                    },
                    type: "warning",
                    posetiveText: "Ok",
                    negativeText: "Cancel"
                  })
                }else if(pages == 7 && expectedView != "" && parseInt(expectedView.replaceAll(",","")) >= 1000){
                  nextPage_is_valid = true;
                }else if (pages == 8){
                  nextPage_is_valid = true;
    
                }
    
                if(nextPage_is_valid || pages == 9){
                  setPages(pages + 1)
                }
    
                }}>Next</button>

          }

          {(
            ((pages >= 3 && plan == "plus") && (pages <= 6 && plan == "plus")) 
          
          ) && <button className="bottomButtons" onClick={() => {
            
            let nextPage_is_valid = true;
            if(pages == 4 && selectedNich == undefined){
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "You Must Select a niche to continuee",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              
              })         
            }else if(pages == 4 && selectedNich != undefined){
              nextPage_is_valid = true;
            }
            if(pages == 5 && socialMedia == "telegram"){
               if(campaignPhoto){
                nextPage_is_valid = true;
              } else{
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "You Must Select an image file from your device to continuee, please select an image file and try again HHH: " + campaignPhoto,
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);
  
                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"
                })

              }
            }else if(pages == 5 && fileObjects.length == 0){
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "You Must Select a file from your device to continuee, please select a file and try again. the video file must be less than 45 Seconds",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              })
            } else if(pages == 5 && fileObjects.length > 0 && fileObjects[0].duration <= 45){
              nextPage_is_valid = true;
            } else if(pages == 5 && fileObjects.length > 0 && fileObjects[0].duration > 45){

              setErrorObject({
                errorTitle: "warning",
                errorMessage: "The video file you selected is longer than 45 seconds, please select a file that is less than 45 seconds",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              })

            }else if (pages == 6 && selectedDate == "" && description == ""){
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "You Must Select a Start Date and add a description to continuee",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              })
            }else if(pages == 6 && selectedDate != "" && description != ""){
              nextPage_is_valid = true;
            }else if(pages == 7 && expectedView == ""){
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "You Must add an expected view count to continuee",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              })
            }else if(pages == 7 && expectedView != "" && parseInt(expectedView.replaceAll(",","")) < 1000){
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "The expected view count must be greater than 1000",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"
              })
            }else if(pages == 7 && expectedView != "" && parseInt(expectedView.replaceAll(",","")) >= 1000){
              nextPage_is_valid = true;
            }else if (pages == 8){
              nextPage_is_valid = true;

            }

            if(nextPage_is_valid || pages == 9){
              setPages(pages + 1)
            }

            }}>Next</button>
            }


          {(pages === 8 && plan == "standard") && <button className="bottomButtons" onClick={() => {
            setPages(pages + 1);
            let campaignBody = getCampaignBody();
            var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
            setLoadingCreators(true);
            axios.post(`${url}/business/campaign/create/new`, campaignBody,{
              headers: {
                  "Authorization": "Bearer " + userInfo
              }
          } ).then((res) => {
              console.log("Campaign created: ", res.data)
              let arrayCreatorList = convertDictToArray(res.data.creatorList);
              setSelectedCreatorList(arrayCreatorList);
              setLoadingCreators(false);
              setCampaignId(res.data.campaignId);

            }).catch((err) => {
              console.log(err)
            });


            //  setIsExploding(true)
          }}>Submit</button>}
        </div>

      </div>
      {(pages === 10 && plan == "standard" ) || (pages == 8 && plan == "plus") && (
        <div className="fadeIn">
          <button
          className="bottomButtons"
            onClick={() => {
              var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

              axios.post(`${url}/campaign/pay/business`, paymentInfo ,{
                headers: {
                    "Authorization": "Bearer " + userInfo
                }
            })
                .then((res) => {
                  console.log('Campaign created:', res);
                  setPages(11);
                  setIsExploding(true);
                
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Create Campaign
          </button>
        </div>
      )}

   
    </div>
  );
};

export default CreateCampaignNew;
