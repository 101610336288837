import React from 'react'
import affordable from "../../component/assets/images/affordable 1.png"
import options from "../../component/assets/images/options2 (1).png"
import idea from "../../component/assets/images/idea.png"
import Slider from '../../component/slider/slider'
import { RiMoneyCnyBoxFill } from 'react-icons/ri'
import { HiOutlineCursorClick } from 'react-icons/hi';
import { VscLaw } from 'react-icons/vsc';
import { GiBrain } from 'react-icons/gi';
import { FcIdea } from 'react-icons/fc';
import { MdOutlinePriceCheck } from 'react-icons/md';
import { HiHomeModern } from 'react-icons/hi2';

const WhyZeter = () => {
  return (
    <Slider className="w-100%" speed={50} delay={5}>
    <div className="relative mt-6 flex w-96 flex-col rounded-xl bg-[#F9F9F9] bg-clip-border text-gray-700 shadow-2xl mr-10">
      <div className="p-6 mb-3" >

        {/* <img src={affordable} height={60} width={60} /> */}
        <MdOutlinePriceCheck size={60} />


        <h5 className="mb-2 block  text-xl font-semibold leading-snug tracking-normal text-[#6968FF] antialiased text-left">
          Easy & Affordable
        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased text-left">
          Discover a user-friendly platform that makes advertising a breeze, without breaking the bank. Our system is designed to provide easy and affordable solutions to effectively showcase your products.

        </p>
      </div>

    </div>
    <div className="relative mt-6 flex w-96 flex-col rounded-xl bg-[#F9F9F9] bg-clip-border text-gray-700 shadow-2xl mr-10">
      <div className="p-6 mb-3" >

        {/* <img src={options} height={60} width={60} /> */}
        <HiOutlineCursorClick size={60} />

        <h5 className="mb-2 block  text-xl font-semibold leading-snug tracking-normal text-[#6968FF] antialiased text-left">
          Variety of options
        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased text-left">
          Choose from a wide range of advertising choices that are tailored to your particular needs, Whether it's social media promotions, influencer partnerships, or targeted campaigns, we've got you covered.

        </p>
      </div>

    </div>
    <div className="relative mt-6 flex w-96 flex-col rounded-xl bg-[#F9F9F9] bg-clip-border text-gray-700 shadow-2xl mr-10">
      <div className="p-6 mb-16" >

        {/* <img src={idea} height={60} width={60} /> */}
        <FcIdea size={60} />

        <h5 className="mb-2 block  text-xl font-semibold leading-snug tracking-normal text-[#6968FF] antialiased text-left">
          Creative and Customizable
        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased text-left">
          Fuel your creative spirit! Customize your advertising strategy to mirror your brand's personality and aspirations.
        </p>
      </div>

    </div>
    <div className="relative mt-6 flex w-96 flex-col rounded-xl bg-[#F9F9F9] bg-clip-border text-gray-700 shadow-2xl mr-10">
      <div className="p-6 mb-16" >

        {/* <img src={idea} height={60} width={60} /> */}
        <VscLaw size={60} />

        <h5 className="mb-2 block  text-xl font-semibold leading-snug tracking-normal text-[#6968FF] antialiased text-left">
          Legal
        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased text-left">
        Trust in legality! Our platform ensures that every advertising avenue is not only effective but also compliant with legal standards.

        </p>
      </div>

    </div>

    <div className="relative mt-6 flex w-96 flex-col rounded-xl bg-[#F9F9F9] bg-clip-border text-gray-700 shadow-2xl mr-10">
      <div className="p-6 mb-16" >

        {/* <img src={idea} height={60} width={60} /> */}
        <HiHomeModern size={60} />

        <h5 className="mb-2 block  text-xl font-semibold leading-snug tracking-normal text-[#6968FF] antialiased text-left">
        Pioneering the Future

        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased text-left">
        Say goodbye to traditional advertising methods! Embrace a new era of effective promotion with our innovative systems.

        </p>
      </div>

    </div>
    <link
    rel="stylesheet"
    href="https://unpkg.com/@material-tailwind/html@latest/styles/material-tailwind.css"
  />
  </Slider>
  )
}

export default WhyZeter
