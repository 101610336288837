// src/context/NavigationContext.js
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext("NavigationContext");

export const NavigationProvider = ({ children }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handle403 = () => {
      navigate('/forbidden');
    };
    
    window.addEventListener('axios403', handle403);

    return () => {
      window.removeEventListener('axios403', handle403);
    };
  }, [navigate]);

  return (
    <NavigationContext.Provider value={""}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
