import Chart, { ChartData } from "chart.js";
import { useRef } from "react";
import "./style.customChart.css";

interface Props {
  props: ChartData;
  type?: string;
  tooltip?: any;
}

const CustomChart = (propData: Props) => {

  const chartData: ChartData = propData.props as unknown as ChartData;

  // const formatData = (
  //   data: number[],
  //   labels: string[],
  //   tooltip?: any
  // ): Chart.ChartData => ({
  //   labels: labels,
  //   datasets: [{ data }],
  // });

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: propData.type,
        data: chartData,

        options: {
          responsive: true,
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                // color: 'red',
                usePointStyle: true,
              },
            },

            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };
  // useEffect(() => {
  //   if (chartRef.current) {
  //     chartRef.current.update();
  //   }
  // }, [propData.type]);
  // useEffect(() => {
  //   if (chartRef.current) {
  //     chartRef.current.data = chartData;

  //     chartRef.current.update();
  //   }
  // }, [chartData, propData.type]);

  return (
    <div className="canvasContainer">
      <canvas ref={canvasCallback} style={{ width: "100%"}}></canvas>
      {/* <canvas ref={canvasCallback} style={{ width: "423" }}></canvas> */}
    </div>
  );
};

export default CustomChart;
