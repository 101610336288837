import axios from "axios";
import { url } from "../../../util/constant";
import axiosInstance from "../../../axiosConfig";



export async function getCreatorList(){
    return new Promise((resolve,reject) =>{
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        axiosInstance.get(url+'/creator/getAll',{
            headers: {
              "Authorization": "Bearer " + userInfo
            }
          }).then((res:any) => {
            console.log("RES: ", res);
            resolve(res.data);
          }).catch((err:any) => {
            console.log("ERRR : ", err);
            reject(err);
          })
    })
}

export async function getTopCreators(){
  return new Promise((resolve,reject) =>{
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
      axiosInstance.get(url+'/creator/statstic/getTopCreators',{
          headers: {
            "Authorization": "Bearer " + userInfo
          }
        }).then((res:any) => {
          console.log("RES: ", res.data);
          resolve(res.data);
        }).catch((err:any) => {
          console.log("ERRR : ", err);
          reject(err);
        })
  })
}
export function filterProperties(arr: { [key: string]: any }[], propsToKeep: string[]): { [key: string]: any }[] {
  if (!Array.isArray(arr)) {
    throw new Error('Input is not an array');
  }

  const filteredArray: { [key: string]: any }[] = [];
  
  arr.forEach(obj => {
    const filteredObj: { [key: string]: any } = {};
    propsToKeep.forEach(prop => {
      if (obj.hasOwnProperty(prop)) {
        filteredObj[prop] = obj[prop];
      }
    });
    // Add property "id" to the filtered object with the value of the "Id" property
    filteredObj['id'] = obj['Id'];
    filteredArray.push(filteredObj);
  });
  
  return filteredArray;
}


export async function getTrendingCreators(niche:string){
  return new Promise((resolve,reject) =>{
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
      axiosInstance.get(url+'/creator/trendingCreators/:' + niche,{
          headers: {
            "Authorization": "Bearer " + userInfo
          }
        }).then((res:any) => {
          console.log("RES: ", res.data);
          resolve(res.data);
        }).catch((err:any) => {
          console.log("ERRR : ", err);
          reject(err);
        })
  })
}