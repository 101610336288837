import React from 'react'
import { inputWrapper } from '../prop.inputWrapper'
import "./style.inputWrapper.css"
const InputWrapper = ({label,
    isRequired,
    icon,
    children,
    errorMessage,}:inputWrapper) => {
  return (
    <div className='cTextInput'> 
    <label htmlFor="label">{label} {isRequired && <span style={{ color: "red" }}>*</span>}</label>
    <div style={{ position: 'relative', width:"100%" }}>
        {children}
        {icon && (
          <span style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
            {icon}
          </span>
        )}
      </div>
    <span className={errorMessage ? "errorMessage" : "hide"  }>{errorMessage}</span>
  </div>
  )
}

export default InputWrapper
