import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./style.home.css";
import affordable from "../../component/assets/images/affordable 1.png"
import options from "../../component/assets/images/options2 (1).png"
import idea from "../../component/assets/images/idea.png"
import creatorImg from "../../component/assets/images/creatorImg.svg";
import bImg from "../../component/assets/images/bimg.svg";
import leftSideImg from "../../component/assets/images/leftSideImg.jpg";
import rightSideImg from "../../component/assets/images/rightSideImage.jpg";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "../../component/slider/slider";
import WhyZeter from "./whyzeter";
import { AiFillYoutube } from "react-icons/ai";
import { RiTelegramFill, RiTiktokFill } from "react-icons/ri";



export default function Home() {

  useEffect(() => {
    const url = new URL(window.location.href);

    // Get values from query parameters
    const accessToken = url.searchParams.get("access_token");
    const tokenType = url.searchParams.get("token_type");
    const expiresIn = url.searchParams.get("expires_in");
    if (accessToken && tokenType && expiresIn) {
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("token_type", tokenType);
      localStorage.setItem("expires_in", expiresIn);
    }
  }, []);

  return (
    <div className="home">
      <Header title="home" />
      <div className="bottomBody">
        <div className="bottomBody">
          <div className="firstBottomContent">
            <h1 style={{ color: "#6968FF", marginTop: 50 }}>
              How does it work?
            </h1>
            <p style={{ fontSize: 23 }}>
              With the support of creators from all over Ethiopia, we aim to raise awareness for different businesses by using the vast social media reach of our creators.
              Our system ensures that advertising revenue is based on the number of views, making it beneficial for both creators and businesses.

              {" "}
              <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                <Link to="/how_it_works">Learn more..</Link>

              </span>
            </p>
          </div>
        </div>
        {/* className="middleBody" */}
        <div className="howItWorkss">
          {/* <div>
            <img src={officeSpace2} alt="officeSpace" />
          
            <h2 style={{ color: "./6968FF" }}>For Creators</h2>
            <p>
              Diversify your income by taking advantage of your social media
              following by integrating short ads.
            </p>
          </div>

          <div>
            <img src={officeSpace2} alt="officeSpace" />
            <h2 style={{ color: "./6968FF" }}>For Creators</h2>
            <p>
              Diversify your income by taking advantage of your social media
              following by integrating short ads.
            </p>
          </div>

           */}
          <div className="inside">
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <a href="./">
                <img
                  className="rounded-t-lg forImage"
                  src={leftSideImg}
                  alt=""
                />
              </a>
              <div className="p-5">
                <a href="./">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-[#6968FF] dark:text-white">
                    For Creators
                  </h5>
                </a>
                <p className="card-content">
                Diversify your income by taking advantage of your social media following by integrating short ads.
                </p>
                <Link className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  to={`/how_it_works?category=creator`}> Read more
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg></Link>
                {/* <a
                  href="./"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Read more
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a> */}
              </div>
            </div>
          </div>

          <div className="inside">
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <a href="./">
                <img
                  
                  src={rightSideImg}
                  alt=""
                />
              </a>
              <div className="p-5">
                <a href="./">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-[#6968FF] dark:text-white">
                    For Business
                  </h5>
                </a>
                <p className="card-content">
                Why limit yourself to one outlet when you can use them all? Our diverse creators will help you spread your message to target audiences with just a click of a button.
                 </p>
                <Link className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  to={`/how_it_works?category=business`}> Read more
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg></Link>
                {/* <a
                  href="./"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Read more
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bottomBody">

            <div>
              <h1 className="middle-titles" style={{ color: "#6968FF", marginTop: 60, fontSize: 36, fontWeight: 700 }}>
                Make Money on
              </h1>
            </div>
                 <div style={{display: 'flex',marginTop:25,justifyContent: 'space-between',width: 400}}>

                  <AiFillYoutube style={{fontSize: 100, color: "red"}}/>
                  <RiTelegramFill style={{fontSize: 100, color: "#009BDB"}}/>
                  <RiTiktokFill style={{fontSize: 100, color: "black"}}/>

                  </div> 
        </div>
        



        <div className="bottomBody">

          <div>
            <h1  style={{ color: "#6968FF", marginTop: 20, fontSize: 36, fontWeight: 700 }}>
              Why Zeter?
            </h1>
          </div>

          <div className="howItWorkss">


            <WhyZeter />



          </div>



          <div className="firstBottomContent">
            <h1 style={{ color: "#6968FF", marginTop: 60 }}>Zeter’s Vision</h1>
            <p className="middle-titles" style={{ alignSelf: "start", textAlign: "start" }}>
              {" "}
              <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                Zeter
              </span>{" "}
              aims to provide the best platform for businesses & brands to
              advertise their products/services helping them reach their target
              customers involving both big & small creators to reach their goal.
              While helping businesses achieve their goal Zeter also creates an
              extra source of income for hard working creators by monetizing
              their social base.
            </p>
            <p className="middle-titles" style={{ alignSelf: "start", textAlign: "start" }}>
              Inspired by our aim and vision to be a source of income for
              everyone, businesses benefiting from the social attention they
              get, and creators receiving money for their work, we named our
              company{" "}
              <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                {" "}
                ZETER
              </span>
              . In geez language{" "}
              <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                Zeter
              </span>{" "}
              means source (ምንጭ) , accurately describing our vision.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}
