import { useMemo } from "react";
import "./style.youtube.css";
import "./style.mobile.youtube.css";
import Table from "../../../component/table/table";
import { useEffect,useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { GetColumns, getRows } from "./controller.youtube";
import { CircularProgress } from "@mui/material";
import { getAgeGroup, getNicheList } from "../telegram/telegram_list/controller.telegram";
import { google_oauth_link } from "../../../util/constant";
import { TbPlugConnected } from "react-icons/tb";

export default function YoutubeAccounts(){

      const [data,setData] = useState<any>([]);
      const [columns,setColumns] = useState<GridColDef[]>([]);
      const [loading,setLoading] = useState<boolean>(false);
      const [nicheList,setNicheList] = useState<any[]>([]);
      const [ageGroup,setAgeGroup] = useState<any[]>([]);
      const [updatedContent, setUpdateContent] = useState<any>({});

      const youtubeInfo = GetColumns((avgViews: any) => { setUpdateContent(avgViews) },updatedContent, nicheList, ageGroup);

      useEffect(()=>{
        setLoading(true);
          
          async function getRowsAsync(){
            var rowDataList = await getRows();
            console.log("rowdata",rowDataList);
              setData(rowDataList);
            setLoading(false);

          }
          async function getLookup() {
            let nicheList: any[] = await getNicheList() as any[];
            let ageGroup: any[] = await getAgeGroup() as any[];
            setNicheList(nicheList);
            setAgeGroup(ageGroup);
            console.log("NicheList: ", nicheList);
            console.log("AgeGroup: ", ageGroup);
            setColumns(youtubeInfo);
          }
          getLookup();
          getRowsAsync();
      },[])

      useEffect(()=>{
        // alert("test");
        setColumns(youtubeInfo);
      },[nicheList,updatedContent,ageGroup])


      const [optionList,setOptionList] = useState<string[]>(["Verification","Completed","Waiting For Payment","On Progress","Draft","Production"]);

    return (
        <div className="orderScreen">
           <button        onClick={()=>{
              window.open(google_oauth_link);

            }} className={"mobileConnect"} >
        <TbPlugConnected />
      </button>
          <div className="campaignList-header" style={{marginBottom: -14}}>
          
          <h1 style={{color:'white',fontWeight:'bolder'}}>Connected Accounts</h1>
          <button 
            onClick={()=>{
              window.open(google_oauth_link);

            }}
          style={{background:'#ae2f2f',padding: 5,borderRadius: 10}}>Connect New</button>
            </div>
            <div style={{margin: 0,marginTop: 0,width: ' 100%'}}>
            {loading ? <CircularProgress /> : 
            <Table columns={columns} data={data}  />
    }</div>
        </div>
    );
}