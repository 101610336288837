import { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";
import { RiCloseCircleFill, RiCopyleftLine, RiFileCopyLine } from "react-icons/ri";
import ManualConnect from "./manualConnectTelegram";
import PaymentComponent from "./paymentComponent";

interface telegramProp{
    closeTelegram: any,
    token: string
}

export default function ConnectTelegram({closeTelegram,token}: telegramProp) {
    const [minute, setMinute] = useState<number>(5); // 2 minutes
    const [second, setSecond] = useState<number>(0); // 0 seconds

    useEffect(() => {
        const interval = setInterval(() => {
            setSecond((prevSecond) => {
                if (prevSecond === 0) {
                    setMinute((prevMinute) => (prevMinute > 0 ? prevMinute - 1 : 0));
                    return 59;
                } else {
                    return prevSecond - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div 
           
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            background: 'rgba(0, 136, 204, 0.19)',
            width: '100%',
            height: '100%',
            zIndex: 10000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                width: 300,
                height: 230,
                backgroundColor: 'white',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                fontWeight: 'bolder',
                zIndex: 100000,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
            }}>
                <div style={{width:'100%',height:50,position:'relative',top:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>

                          <RiCloseCircleFill onClick={()=>{
                            closeTelegram();
                          }} size={20} style={{margin:10}} />
                </div>
            
                <div style={{display:'flex',flexDirection:'row',padding:10,alignItems:'center',justifyContent:'center',fontWeight:'bolder'}}> 
               <h1 style={{width: 200,position:'relative', backgroundColor:'lightgray',borderRadius:10,padding: 5, overflow:'scroll'}}>{token}</h1>
                <span>
                    <button 
                    onClick={()=>{
                        // copy to clipboard javascript code
                        navigator.clipboard.writeText(token);
                        
                        alert("Copied to clip board succesfully");
                    }}
                    style={{ background: 'transparent', padding: 5, borderRadius: 10 }}>
                        
                        <RiFileCopyLine size={20} style={{fontWeight:'bolder'}} />
                    </button>
                </span>
                </div>
                <h1 style={{backgroundColor:'#0088cc',padding:10,width:125,height:40,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', borderRadius: 5,fontSize:24}}>{`${minute}:${second.toString().padStart(2, '0')}`}</h1>
                <span onClick={()=>{
                    // open link in new tab
                    window.open("https://t.me/zeterads_bot","_blank");
                }} style={{backgroundColor:'#0088cc',padding:10,margin:6, width:125,height:40,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', borderRadius: 5,fontSize:14}}>
                    <BiLogoTelegram style={{fontSize:32}} />
                    <p>Open Bot</p>
                </span> 
          
            </div>
        </div>
    );
}
