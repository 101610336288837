import axios from "axios";
import { useState } from "react";
import { url } from "../../../util/constant";
import { Loading } from "../../../component/loading/loading";





export default function YoutubeUpdates() {
    const [isLoading,setIsLoading] = useState<boolean>(false);

    return (
        <div>
            {
                <Loading loading={isLoading} setLoading={setIsLoading}/>
            }
                <button
                onClick={()=>{
                    setIsLoading(true);
                    axios.get(url + "/youtube/update_youtube_views").then((res)=>{
                        setIsLoading(false);
                        console.log(res);
                    }
                    ).catch((err)=>{
                        setIsLoading(false);

                        console.log(err);
                    })
                    
                }}
                style={{backgroundColor:'lightblue',padding: 10}}> Update Youtube </button>
        </div>
    )
}