import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import { useState } from "react";
import { getJson } from "../../component/forms/controller.form";
import { Loading } from "../../component/loading/loading";
import "./style.mobile.creatorLogin.css";
import "./style.creatorLogin.css";
import { Link, useNavigate } from "react-router-dom";
import { loginController } from "./controller.login";
import { AiFillHome } from "react-icons/ai";
import logo from "../../component/assets/images/zeter adv white logo1.png";
import { AlignCenter } from "lucide-react";
export default function BusinessLogin() {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formData] = useState<formBuilder[]>([
    {
      label: "User Name",
      name: "userName",
      value: "",
      type: "TextInput",
      placeholder: "User Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Password",
      name: "password",
      value: "",
      type: "TextInput",
      placeholder: "Password",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
  ]);

  const handleClose = () => {
    setShowErrorMessage(false);
    // Additional logic for closing the component
  };

  const [start] = useState(0);
  const [end] = useState(3);
  const [updatedContent, setUpdatedContent] = useState<formBuilder[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorVal, setErrorValue] = useState("");
  const navigator = useNavigate();
  return (
    <>

      <div className="createAccount-back">
        <div className="logoContainer">
          <Link to={"/"}><img className="logo" src={logo} alt="logo" /></Link>


          <div></div>
        </div>
        <div className="createAccount">

          <Loading loading={loading} setLoading={"sef"} />

          <div className="loginContent-login">


            <div
              className="inline-flex rounded-md shadow-sm w-9/12 mx-4 my-4"
              role="group"
            >
              <Link
                to={"/creator_login"}
                type="button"
                className="px-4 py-2 w-1/2 text-sm font-medium text-gray-900 bg-transparent border  rounded-s-lg hover:bg-primary-color hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-700 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                Creator
              </Link>
              <Link
                to={"/business_login"}
                type="button"
                className="px-4 py-2 w-1/2 text-sm font-medium text-white bg-primary-color border  rounded-e-lg focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                Business
              </Link>
            </div>
            <div className={"formWrapper-login"}>
              {errorVal === "" ? (
                <></>
              ) : (
                <div className="LoginerrorContainer">
                  <p>{errorVal}</p>
                </div>
              )}
              {CForm(
                formData,
                (updatedDate: formBuilder[]) => {
                  setUpdatedContent(updatedDate);
                },
                start,
                end
              )}
            </div>
            <div className={"actions-login"}>
              <button
                onClick={async () => {
                  // var content = await getJson(updatedContent || []);

                  // setLoading(true);
                  // await loginController(content);
                  // setTimeout(()=>{
                  //   setLoading(false);
                  //   navigator("/dashboard/business/home");
                  // },4000);
                  var content = await getJson(updatedContent || []);
                  console.log("content: ", content);
                  var contentArray = Object.values(content);
                  console.log(contentArray);
                  if (contentArray[0] === "") {
                    setErrorValue("Please enter your username.");
                  } else if (contentArray[1] === "") {
                    setErrorValue("Please enter your password.");
                  } else if (contentArray.length === 0) {
                    setErrorValue("Please enter your username & password.");
                  } else {
                    setErrorValue("");
                    setLoading(true);
                    await loginController(content, "business")
                      .then((res: any) => {
                        if (res.data === "Invalid username or password") {
                          setErrorValue(
                            "invalid username or password please try again!"
                          );
                          setLoading(false);
                        } else {
                          navigator("/dashboard/business/home");
                        }
                      })
                      .catch((err) => {
                        console.log("error: ", err);
                        setShowErrorMessage(true);
                        setLoading(false);
                      });
                  }
                }}
                className={"roundButton me-1 ms-1 font-bold text-md"}
              >
                {"Login"}
              </button>
              <div>
                <Link to="/" className="text-sm font-semibold text-primary-color">
                  Forget Password?
                </Link>
              </div>
              <div className="flex flex-row justify-center align-middle items-center">
                <p className="text-sm m-1">Didn't have an account?</p>{" "}
                <Link
                  to="/business-create-account"
                  className="text-sm font-bold text-primary-color"
                >
                  Register
                </Link>
              </div>
            </div>
          </div>
          <div className="loginDecore">

            <svg
              className="w-10 mb-5 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 14"
            >
              <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
            </svg>
            <h1>
              <i>
              Elevate Your Brand: Collaborate with Creators, Amplify Your Reach!

         </i>
            </h1>
            {/* <img src={tools} alt="tools" /> */}
          </div>
        </div>
      </div>

    </>
  );
}
