import Footer from "../../component/footer/footer";
import Header from "../../component/header/header";
import "./style.about_us.css"
export default function AboutUs() {

    return (

        <>
            <div className="aboutUs">
                <Header title="about_us" />
                <div className="bodyContent">
                    <div className="bottomBody">
                        <div className="firstBottomContent">
                            <h1 style={{ color: "#6968FF", marginTop: 50 }}>
                                Zeter’s Vision
                            </h1>
                            <p style={{ color: "#fff", textAlign: "center" }}>
                                <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                                    {" "} Zeter

                                </span>  aspires to be the best platform, offering businesses
                                and brands a comfortable and smooth environment in which prosperity
                                thrives. While assisting businesses in achieving their goals,
                                <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                                    {" "} Zeter

                                </span> also provides an ecosystem in which hardworking creators can
                                make more money by monetizing their social network.

                                <br /> <br />

                                Inspired  by our aim and vision to be a source of
                                income for everyone by creating an environment that
                                promotes growth, we named our company <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                                    {" "} ZETER

                                </span> . In geez
                                language <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                                    {" "} Zeter

                                </span>  means source <span style={{ color: "#6968FF", fontWeight: "bold" }}>
                                    {" "} (ምንጭ)

                                </span> , accurately describing our vision.



                            </p>

                            <br />
                            <h1 style={{ color: "#6968FF", marginTop: 50 }}>
                                Zeter’s Mission
                            </h1>
                            <p style={{ color: "#fff", textAlign: "center" }}>
                                At Zeter , our mission is to redefine the advertising landscape by seamlessly integrating honesty, integrity, growth orientation, accountability, adaptability, and creativity into every facet of our operations. We strive to be synonymous with advertising, setting new standards for authenticity and innovation.
                                <br /> <br />
                                To become the gold standard in advertising, synonymous with innovation, We aim to create lasting partnerships with our clients, guiding them toward success through strategic, creative, and results-driven advertising solutions. By consistently delivering on our core values, we aspire to reshape the industry and inspire others to raise their standards, ensuring that Zeter is the go-to choice for those seeking unparalleled advertising excellence.
                            </p>
                        </div>
                    </div>
                </div>


            </div>
            <Footer />



        </>

    )
}
