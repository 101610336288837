import { checkBoxProp } from "./prop.checkBox";
import "./style.checkBox.css";


export default function CheckBox(checkBoxProp: checkBoxProp){
    //const [checked,setChecked] = useState<boolean[]>(checkBoxProp.checkListValues);
    return(
        <div className="checkBoxes"> 
        <h3>{checkBoxProp.title}</h3>
        {checkBoxProp.list.map((res,index)=>(
             <div className="checkBox">
             <input
     
       
             type="checkbox"
             checked={checkBoxProp.checkListValues[index]}
             onChange={(selected)=>{
                   checkBoxProp.callback(index);
             }}
           />
           <p>{res}</p>
           </div>
        ))}
       
      </div>
    );
}