import "./style.home.css";
import { useEffect, useState } from "react";
import "./style.mobile.home.css";
import { ChartData } from "chart.js";
import { ComponentPopUp } from "../../../component/popup/componentPopup";
import Table from "../../../component/table/table";
import { GridColDef } from "@mui/x-data-grid";
import SingleCreator from "../../../component/single_creator/single_creator";
import { useNavigate } from "react-router-dom";
import ContentCard from "../../../component/content_card/content_card";
import {  filterProperties, getTopCreators, getTrendingCreators } from "./controller.home";
import { creatorProp } from "../../../component/single_creator/prop.single_creator";

export default function BusinessDashboardHome() {
  // const [fhfData, setFhfData] = useState<ChartData>({
  //   labels: ["cp 1", "cp 2", "cp 3", "cp 4", "cp 5", "cp 6", "cp 7"],

  //   datasets: [
  //     {
  //       label: "Expected View",
  //       // borderColor: theme.teritaryChartColor,
  //       backgroundColor: "#2B3674",

  //       data: [2200, 320, 2380, 7740, 2340, 3160, 1330, 4350, 2230, 1560],

  //       // barPercentage:20,
  //     },
  //     {
  //       label: "Actual View",
  //       // borderColor: theme.teritaryChartColor,
  //       backgroundColor: "#707EAE",
  //       borderWidth: 1,

  //       pointRadius: 5,
  //       pointBorderColor: "black",
  //       data: [2300, 380, 2300, 6340, 1730, 5160, 2230, 350, 230, 560],

  //       // barPercentage:20,
  //     },
  //   ],
  // });
  const [popup, setPopUp] = useState(false);
  const [weeklyOrder, setWeeklyOrder] = useState<ChartData>({
    labels: ["D 1", "D 2", "D 3", "D 4", "D 5", "D 6", "D 7"],

    datasets: [
      {
        label: "Orders",
        // borderColor: theme.teritaryChartColor,
        backgroundColor: "#2B3674",

        data: [2200, 320, 2380, 7740, 2340, 3160, 1330, 4350, 2230, 1560],

        // barPercentage:20,
      },
    ],
  });

  const [socialMediaOrder, setSocialMediaOrder] = useState<GridColDef[]>([
    { field: "FirstName", headerName: "Name", width: 200 },
    { field: "TotalAvgView", headerName: "Views", width: 200 },
  ]);
  const [socialMediaOrderData, setSocialMediaOrderData] = useState<{[key: string]: string}[]>([]);
  const [trendingCreators, setTrendingCreators] = useState<{[key: string]: string}[]>([]);
  const [niche, setNiche] = useState("music");
  const [numberOfCreatorsToShow, setNumberOfCreatorsToShow] = useState(4);
  

  
  useEffect(() => {
    getTopCreators().then((data:any)=> {
      console.log(data)
      setSocialMediaOrderData(data)})    
  }, []);

  useEffect(() => {
    getTrendingCreators(niche).then((data:any)=>{
    setTrendingCreators(data)
    console.log("niche",data)
  })
    console.log("sdf", socialMediaOrderData);
    console.log("xxxxxxx", trendingCreators);
    var GoogleChartToolbarItems: ["download"];
  
  }, []);
  


  const navigator = useNavigate();

  return (
    <div className="homeMain">
      
        <ComponentPopUp
        isOpen={popup}
        title=""
        message=""
        customComponent={  <ContentCard
          title={"Titel"}
          description={"How to Create An Account"}
          url={
            "https://www.youtube.com/embed/UipGxBdYdVA?si=HE3zZpfk_FbQghd3"
          }
        />}
        onAccept={()=>{}}
        onClose={function (): void {
          setPopUp(false);
        }}
      />
      <div className="homeBusinessContent">
        <div className="topContent-banner">
          <h1>
            Create, click and <br></br> spread your Campaign
          </h1>
          <p>
            Spread your message using both traditional <br></br> and interactive
            ads.
          </p>
          <div className="buttonOptions">
            <button
              onClick={() => {
                // navigator("/dashboard/business/campaign");
                navigator("/dashboard/business/campaign-new");
              }}
              className="buttonSilent"
            >
              New Campaign
            </button>
            <button className="buttonSilent" onClick={()=>{
               setPopUp(true);  
            }}> Watch Video</button>
          </div>
        </div>
        <h1 className="trending-creators">Trending Creators</h1>
        <ul className="trendingCreatorLists">
          <li className="me-2  dark:bg-gray-800 dark:text-blue-500">
            <a
             href="#"
              onClick={()=>setNiche("music")}
              aria-current="page"
              className="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active"
            >
              Music/Entertainment
            </a>
          </li>
          <li className="me-2  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">
            <a  href="#"
            onClick={()=>setNiche("life-style")}
             className="inline-block p-4 rounded-t-lg">
              Life Style
            </a>
          </li>
          <li className="me-2  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">
            <a  href="#"
            onClick={()=>setNiche("vlog")}
           className="inline-block p-4 rounded-t-lg">
              Vlog
            </a>
          </li>
          <li className="me-2 hover:bg-gray-50 dark:hover:text-gray-300  hover:text-gray-600 dark:hover:bg-gray-800">
            <a
            onClick={()=>setNiche("food")} 
            href="#" className="inline-block p-4 rounded-t-lg">
              Food
            </a>
          </li>
        </ul>
        <div className="trandingCreators">

        

{trendingCreators.slice(0, numberOfCreatorsToShow).map((creator:any, index) => (
 <>
 <SingleCreator key={index} creator={creator} />

 </>

  

))}
          {/* <SingleCreator />
          <SingleCreator />

          <SingleCreator />
          <SingleCreator /> */}
        </div>
        <div className="creatorListing">
          <div className="tableHeaderCreator">
            <h1 className="trending-creators">Top Creators</h1>
            <button className="buttonThem" onClick={()=>{
              numberOfCreatorsToShow <=4 ? 
              setNumberOfCreatorsToShow(trendingCreators.length) : setNumberOfCreatorsToShow(4)}}>{numberOfCreatorsToShow <= 4 ? "View All": "View less" }</button>
          </div>
          <Table columns={socialMediaOrder} data={ filterProperties(socialMediaOrderData,['Id', 'FirstName', 'TotalAvgView'] )} />
        </div>
      </div>
    </div>
  );
}
