import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../../../../util/constant';
import planeLoading from '../../../../component/assets/images/planeLoading.gif';
import { ErrorComponentProps } from './ErrorComponent';

interface Props {
    creators: any[];
    setPages: (value: number) => void;
    campaignId: number;
    loading: boolean;
    setLoading: (value: boolean) => void;
    nichId: number;
    setSelectedCreatorList: (value: any) => void;
    setErrorObject: (value: ErrorComponentProps) => void;
}

const SelectedCreators: React.FC<Props> = ({ creators, campaignId, setPages,loading,setLoading, nichId,setSelectedCreatorList, setErrorObject }) => {



    return (
        <div className="fadeIn">
            <div>
                <p className='question'> Selected Creator List </p>
                <p style={{textAlign: 'start', color:'gray', fontSize: 17}}>Please do Note that you can only remove only 3 creators and they will be replaced with new once imeditly, if you would like to use a specific creator please use <a style={{color: "#2D2CAB"}} href="/dashboard/business/campaign-new" > Zeter Plus </a>  </p>
                <div className="creator-list">
                { loading ? <div className="loader">

                    <img src={planeLoading} width={'100%'} height={'100%'}  style={{alignSelf: 'center',margin: 'auto', marginLeft: '120%'}}/>
                </div> :
                    creators.map((c: any) => {
                        return (
                        
                            <div className="creator-card">
                              {c.FirstName}

                                <button className="remove-creator" onClick={() => {
                                    console.log("REMOVE CREATOR: ", c);
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
                                        setLoading(true);
                                    axios.post(`${url}/business/campaign/reject/creator_id/${c.socialMediaAccountId}/campaign_id/${campaignId}/nich_id/21`,
                                    {
                                        
                                    },{
                                        headers: {
                                            "Authorization": "Bearer " + userInfo   
                                        }
                                    }    
                                    ).then((res) => {
                                        console.log(res.data)
                                        if(res.data.error || res.data.warning){
                                            setErrorObject({
                                                type: res.data.error,
                                                errorMessage: res.data.warning,
                                                posetiveAction: () => {
                                                    setLoading(false);
                                                    setErrorObject({} as ErrorComponentProps);

                                                },
                                                negativeAction: () => {
                                                    setLoading(false);
                                                    setErrorObject({} as ErrorComponentProps);

                                                },
                                                errorTitle: res.data.error? "Error" : "Warning",
                                            });
                                         
                                        }else{

                                            setSelectedCreatorList((prev: any) => {
                                                let old = [...prev];
                                                if(res.data.replacement){
                                                    let final = old.concat(res.data.replacment);
                                                    return final;
                                                }
                                              return old;
                                        });
                                        setLoading(false);

                                        }
                                   
                                      
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                }}>Remove</button>
                            </div>
                            
                            
                        )
                    })
                }
                </div>

            </div>
        </div>
    );
};

export default SelectedCreators;
