import React from 'react'
import Marquee from "react-fast-marquee";
import { sliderProp } from './prop.slider';

const Slider = ({children, speed, delay, className}:sliderProp) => {
  return (
    <Marquee className={className} direction="right" speed={speed} delay={delay} pauseOnHover>
   {children}
  </Marquee>

  )
}

export default Slider
