import { useState } from "react";

interface Props {
text: string,
maxLength: number
}

const ShortenText = ({ text, maxLength }:Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const truncatedText =isExpanded ? text : text.slice(0, maxLength) ;
  
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
 <>
        <p>{truncatedText} <span> {text.length > maxLength && (
          <p onClick={handleToggle}>
            {isExpanded ? 'Read less' : 'Read more...'}
          </p>
        )}</span> </p>
       
  </>
    );
  };
  
  export default ShortenText;