import { useMemo } from "react";
import "./styles/style.orders.css";
import Table from "../../../component/table/table";
import { useEffect,useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getRows } from "./controller.campaign";

export default function ListCampaign(){
      const [data,setData] = useState<any>([]);
      const [columns,setColumns] = useState<GridColDef[]>([]);
      const [notFilteredData,setNotFilteredData] = useState<any>([]);
        const [selectedOption,setSelectedOption] = useState<string>("All");
      useEffect(()=>{
          setColumns(getColumns());
          async function getRowsAsync(){
            let rowDataList = await getRows();
            setData(rowDataList);
            setNotFilteredData(rowDataList);
          }
          getRowsAsync();
      },[]) 

      const [optionList,setOptionList] = useState<string[]>(["All","distribution","draft","production","Completed"]);

    return (
        <div className="orderScreen">
            <div className="campaignList-header">
                {optionList.map((option,index)=>{
                    if(option == selectedOption){
                        return(
                            <span key={index} className={`option-selected`}>{option}</span>
                        )
                    }
                    return(
                     
                        <span onClick={()=>{
                            if(option == "All"){
                              setData(notFilteredData);
                              setSelectedOption(option);
                              return;
                            }
                          setSelectedOption(option);

                          console.log("Data: ", data);
                        let oldData = notFilteredData;
                          oldData = oldData.filter((item:any)=>{
                            if(item.campaignStatus == option){
                              return item;
                            }
                          }
                          )
                          setData(oldData);


                        }} key={index}  className={`option`}>{option}</span>
                    )
                
                })
                }
            </div>
            
            
             <div style={{margin: 20,marginTop: 0,width: '97%'}}>
            <Table columns={columns} data={data} />
            </div>
        </div>
    );
}