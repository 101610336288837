
import "./style.single_creator.css"
import "./style.mobile.creator.css";
import { creatorProp } from "./prop.single_creator";
import avater from "../assets/images/avater.webp";
export interface SingleCreatorProps {
  creator: creatorProp;
}
export default function SingleCreator({creator}: SingleCreatorProps) {
  return (
    <div className="singleCreator">
      <div className="innerContainer">
        <div className="image">  <img className="rounded-t-lg forImage" alt="creator"  src={creator.identificationImage? creator.identificationImage : avater} /></div>
      
        <div className="singleCreatorInfo">
          <div className="personalInfo">
            <h2>{creator.firstName} {" "} {creator.lastName}</h2>
            <p>
              {creator.city}
            </p>
          </div>

        </div>

        <div className="singleCreatorInfo">
          {/* <h3>{creator.phoneNumber} </h3> */}
          <button onClick={()=>{
            window.location.href = `/dashboard/business/creator/${creator.id}`
          }} >View</button>
        </div>
      </div>
    </div>
  );
}
