import React from 'react'
import { inputProps } from './props.input'
import InputWrapper from '../inputWrapper/inputWrapper'
import { Input } from '@mui/material'
import "./style.input.css"
const FormInput = ({
    type,
    label,
    placeholder,
    isRequired,
    icon,
    errorMessage,
    onChange,
}: inputProps) => {
    return (
        <InputWrapper label={label} isRequired={isRequired} icon={icon} errorMessage={errorMessage}>

            <input className={`${type === 'password' ? "passwordField" : "textInput"} ${errorMessage ? "error" : ""}`} type={type} id={label} placeholder={placeholder} onChange={onChange} />
        </InputWrapper>
    )
}

export default FormInput
