import { useMemo } from "react";
import "./style.payment.css";
import Table from "../../../component/table/table";
import { useEffect,useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getRows } from "./controller.paymentVerefication";

export default function ListPayment(){
      const [data,setData] = useState<any>([]);
      const [columns,setColumns] = useState<GridColDef[]>([]);
      useEffect(()=>{
          setColumns(getColumns());
          async function getRowsAsync(){
            setData(await getRows());
          }
          getRowsAsync();
      },[]) 

      const [optionList,setOptionList] = useState<string[]>(["Waiting Approval","Valid","Invalid"]);

    return (
        <div className="orderScreen">
            <div className="campaignList-header">
                {optionList.map((option,index)=>{
                    if(index == 0){
                        return(
                            <span key={index} className={`option-selected`}>{option}</span>
                        )
                    }
                    return(
                     
                        <span key={index}  className={`option`}>{option}</span>
                    )
                
                })
                }
            </div>
            <h2 className="trending-creators">Campaigns</h2>
            <Table columns={columns} data={data} />
        </div>
    );
}