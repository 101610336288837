import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography, FormControl, InputLabel, OutlinedInput, Chip, useTheme } from '@mui/material';
import { getTopCreators } from '../../dashboard_business/home/controller.home';
import { url } from '../../../util/constant';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];





function getStyles(name:string, personName: string[], theme:any) {
  
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}

export default function TelegramMessage() {
  const theme = useTheme();
  const [personName, setPersonName] = useState<string[]>([]);
  const [creatorOptions,setCreatorOptions]  = useState<any[]>([]);
  const [message, setMessage] = useState('');

  const handleSelectChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    // Handle send message logic here
    console.log('Recipients:', personName);
    console.log('Message:', message);
  };

  useEffect(()=>{
        async function getAllCreators(){
        axios.get(url+'/creator').then((res:any)=>{
            console.log(res.data);
            setCreatorOptions(res.data);
        }).catch((err:any)=>{

        })
        // const creatorList = await getTopCreators();
            // setCreatorOptions(creatorList);
        }

        getAllCreators();
  },[])

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Telegram Message
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multiple-select-label">Recipients</InputLabel>
        <Select
          labelId="multiple-select-label"
          id="multiple-select"
          multiple
          value={personName}
          onChange={handleSelectChange}
          input={<OutlinedInput label="Recipients" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {creatorOptions.map((name) => (
            <MenuItem key={name.id} value={name.firstName} style={getStyles(name, personName, theme)}>
              {name.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Message"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={message}
        onChange={handleMessageChange}
        sx={{ m: 1, width: '100%' }}
      />
      <Button variant="contained" color="primary" onClick={handleSend} sx={{ m: 1 }}>
        Send
      </Button>
    </Box>
  );
}
