import { MdCampaign, MdOutlineAttachMoney } from "react-icons/md";
import SingleCardContent from "../../../component/singleCardContent/SingleCardContent";
import { orderProp } from "./prop.order";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrFormView } from "react-icons/gr";

import "./style.orders.css";
import "./style.mobile.orders.css";
import { useState, useEffect } from "react";
import { getCampaignDetail } from "../../dashboard_business/campaign/controller.campaign";
import { acceptCampaign, getCreatorCampaignDetail, getStatusOfTheCampaign, isVideoUploaded, rejectCampaign } from "./controller.orders";
import VideoUploader from "./video_uploader/VideoUploader";
import AcceptedOrder from "./accepted_orders/AcceptedOrders";
import { Loading } from "../../../component/loading/loading";
import { url } from "inspector";
import { PopUp } from "../../../component/popup/popup";
import { useNavigate, useNavigation } from "react-router-dom";
import OrderStatus from "./order.status";
import ProgressBar from "../../../component/progressBar/progressBar";
import WaitingForBusiness from "./waiting_for_business/waitingForBusiness.view";
import { RiCodeView } from "react-icons/ri";

export default function Order(order: orderProp) {
  const [campaignDetail, setCampaignDetail] = useState<any>({});
  const [status,setStatus] = useState("");
  const [loading,setLoading] = useState(false);
  const [loadingPage,setLoadingPage] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false)
  const navigation = useNavigate();

  const acceptStatus = () => {
    setLoading(true);

  };


  useEffect(() => {
    // alert(JSON.stringify(order))
    setLoadingPage(true);
    getStatusOfTheCampaign(order.id).then((res: any) => { 
      console.log("status body: " , res);
      setStatus(res.status.toString());
      setCampaignDetail(res);
     
      getCreatorCampaignDetail(order.id)
      .then((res: any) => {
       // setCampaignDetail(res[0]);
        setLoadingPage(false);
        console.log("redtsmmm", res);
      })
      .catch((err) => {
        console.log(err);
      });
    }).catch((err) => {

      
    })
   
  }, []);

  return (
    <>
      {false ? <Loading loading={loadingPage} setLoading={(loading: boolean)=>{
            setLoadingPage(loading);
          }} /> :
          <>
          {rejectPopup && <PopUp title="reject" isOpen={rejectPopup} message = {`This Offer will make you ${campaignDetail.TotalPrice} birr. Are you sure you want to reject this offer?`}
 onClose={()=>setRejectPopup(false)} onAccept={()=>{
  rejectCampaign(campaignDetail.id).then((res:any)=>{
              console.log("res",res);
              // check if the response has 200 or 201
              if(res.status === 200 || res.status === 201){
              setStatus("Rejected")
              }else{

              }
              setLoading(false);
            }).catch((err: any)=>{
              console.log("err",err);
              setLoading(false);
            });
          }}/>}
      
      {status === "Accepted" && (
        <>
              <OrderStatus status={status} />

          <WaitingForBusiness />
          </>
      )
      }

      
      {status === "production" &&  !isVideoUploaded(campaignDetail) ? (
        <VideoUploader campaignData={campaignDetail} acceptStatus={()=>{ navigation("/dashboard/order")}} />
      ) :
      (status === "progress")?
      <AcceptedOrder order={campaignDetail} /> :
      (status != "Accepted") &&
      (
        <div className="singleOrder" >
      <OrderStatus status={status} />
      <span className={"whiteSpace"}>
        
      </span>
        <div className="card-campaign-detail">
          <GiTakeMyMoney style={{fontSize: 54,margin:0}} />
          <h1>Expected Earnings</h1>
          <h1>{ campaignDetail.totalPrice }</h1>
    

        </div>
        <div className="card-campaign-detail">
          <RiCodeView style={{fontSize: 54,margin:0,color: 'white'}} color={'white'} />
          <h1>Expected View</h1>
          <h1>{campaignDetail.totalView  }</h1>

        </div>


        
          <div className="order_video_section">
            <iframe
               width="83%"
          height="615"
              src={campaignDetail?.campaign?.shortVideoUrl? campaignDetail?.campaign?.shortVideoUrl.replace("http://","https://").replace("https://www.youtube.com/watch?v=","https://www.youtube.com/embed/")  : ""}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <b>
              <p>{campaignDetail?.campaign?.business?.name}</p>
              <p>{campaignDetail?.campaign?.contentDescription}</p>
              <p>{"Start Date: "+ campaignDetail?.campaign?.startDate}</p>

            </b>
            <p>{campaignDetail.ContentDescription}</p>
            <b>
              <p> {campaignDetail.duration? parseFloat(campaignDetail.duration) +" Sec" : ""} </p>
            </b>
            {campaignDetail.Status !== "Rejected" &&
            <div className="buttonsList">
              <button 
              
                onClick={() =>{

                    setLoading(true);
                    acceptCampaign(campaignDetail.id).then((res:any)=>{
                      console.log("res",res);
                      // check if the response has 200 or 201
                      if(res.status === 200 || res.status === 201){
                      setStatus("Accepted")
                      }else{

                      }
                      setLoading(false);
                    }).catch((err: any)=>{
                      console.log("err",err);
                      setLoading(false);
                    });
                  

                }}
              className="roundButtonBorder-black">
                {"Accept"}
              </button>
              <button onClick={()=>setRejectPopup(true)} className="rejectButton">{"Reject"}</button>


            </div>
      }
          </div>

         
          <Loading loading={loading} setLoading={(loading: boolean)=>{
            setLoading(loading);
          }} />
        </div>
      )}
      </>
}
    </>
  );
}
