import { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";
import { RiCloseCircleFill, RiCopyleftLine, RiFileCopyLine } from "react-icons/ri";
import ManualConnect from "./manualConnectTelegram";
import PaymentComponent from "./paymentComponent";
import { Button } from "@mui/material";
import { getJson } from "../../../../component/forms/controller.form";
import axios from "axios";
import { url } from "../../../../util/constant";
import axiosInstance from "../../../../axiosConfig";

interface AddTelegramChannelProp {
    closeTelegram: any,
}

export default function AddTelegramChannel() {
    const [minute, setMinute] = useState<number>(5); // 2 minutes
    const [second, setSecond] = useState<number>(0); // 0 seconds
    const [telegramInfo, setTelegramInfo] = useState<any>({});
    const [paymentInfo,setPaymentInfo] = useState<any>({});
    useEffect(() => {
        const interval = setInterval(() => {
            setSecond((prevSecond) => {
                if (prevSecond === 0) {
                    setMinute((prevMinute) => (prevMinute > 0 ? prevMinute - 1 : 0));
                    return 59;
                } else {
                    return prevSecond - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (


        <div style={{

            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontWeight: 'bolder',
            width: '100%'
        }}>


            <ManualConnect setTelegramInfo={setTelegramInfo} />
            <PaymentComponent setPaymentInfo={setPaymentInfo} />
            <Button onClick={()=>{
                    let jsonInfo = getJson(telegramInfo);
                    jsonInfo["payment"] = paymentInfo;
                    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

                    axiosInstance.post(url + "/creator/telegram_manual_connect", jsonInfo,{
                        headers: {
                            "Authorization": "Bearer " + userInfo   
                        }}).then((res) => {
                        console.log(res.data);
                        alert("Channel Added Successfully");
                        window.location.href = "/dashboard/telegram";

                    }
                    ).catch((err) => {
                        alert("Something Went Wrong");

                        console.log(err);
                    })

                    
                    console.log("FINAL: ", jsonInfo);
            }} > 
                Add Channel / Group
            </Button>
        </div>

    );
}
