
import {url} from '../../../util/constant';
import axios from 'axios';


export function getCampaignBudget(body:any){
    console.log('body: ', body);
    
     body = {
        "time": body.adLength,
        "nich":body.nish,
        "audience":body.Audience,
        "view":body.expectedViews
    };
  console.log('payload body: ', body);
  
    
    return new Promise((resolve, reject) => {
        axios.post(url+'/campaign/budget', body)
          .then(function (response) {
            console.log(response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}


export function createCampaign(body:any){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

    return new Promise((resolve, reject) => {
        axios.post(url+'/business/campaign/create', body,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log(response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          reject(error);
          });
        });
}

