import React from 'react';
import { handlePlanClick } from '../../../../util/campaign/createCampaign';

interface Props {
    plan: string;
    setPlan: React.Dispatch<React.SetStateAction<string>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const PlanSelection: React.FC<Props> = ({ plan, setPlan, setPages }) => {
    return (
        <div className="fadeIn">
            <p className='question'> Select Campaign Plan</p>
            <div className='youTubeTelegram'>
               
                 <button className={plan === "standard" ? "standardButton standardButtonClicked" : "standardButton"} onClick={() => handlePlanClick("standard", setPlan, setPages)}>Standard

                    <div className="descriptionSection">
<p> zeter standard is all about the asdas dasf sfasd fsaf sdf </p>
                        </div>
                 </button>
               
                <button className={plan === "plus" ? "plusButton plusButtonClicked" : "plusButton"} onClick={() => handlePlanClick("plus", setPlan, setPages)}>Zeter Plus

                <div className="descriptionSection">
<p> zeter Plus is all about the asdas dasf sfasd fsaf sdf </p>
                        </div>

                </button>
            <button className={plan === "premium" ? "premiumButton premiumButtonClicked" : "premiumButton"} onClick={() => handlePlanClick("premium", setPlan, setPages)}>Premium
            <div className="descriptionSection">
<p> Comming soon</p>
                        </div>
            </button>
            </div>
        </div>
    );
};

export default PlanSelection;
