
import React from 'react';
import { DatePickerProp } from './prop.DatePicker';
import "./style.DatePicker.css";
export default function DatePicker(datePicker: DatePickerProp){
    function formatDate(date: Date) {
        let day: string = date.getDate().toString();
        let month: string = (date.getMonth() + 1).toString();
        let year: string = date.getFullYear().toString();
        
    
        if (parseInt(day) < 10) {
            day = '0' + day;
        }
        if (parseInt(month) < 10) {
            month = '0' + month;
        }
        
        return day + '/' + month + '/' + year;
    }

    return(
        <div className="datePicker">
            <label>{datePicker.title}</label>
            <input 
            value={datePicker.value}
            onChange={(tes)=>{
                datePicker.onChange(tes.target.value);
            }} type="date" />

            </div>
    )
}