import "./style.create_account.css";
import logoBlack from "../../component/assets/images/zeter adv black logo1.png"
import tools from "../../component/assets/images/tools.svg";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import { useState } from "react";
import { getJson } from "../../component/forms/controller.form";
import { BussinessAccountJson } from "./api.model.create_account";
import {
  buildCreatorApiBody,
  createAccountApiCall,
} from "./controller.create_account";
import { Loading } from "../../component/loading/loading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ErrorToast from "../../component/toast/error";
import { fileUpload } from "../create_account/controller.create_account";
import FormInput from "../../component/formComponents/input/input";
import { useFormik } from "formik";
import { businessSignupFormikStep1, businessSignupFormikStep2 } from "../../util/formik/businessSignUpForm";

export default function BusinessCreateAccount() {

  const formikStep1 = useFormik(businessSignupFormikStep1)
  const formikStep2 = useFormik({ ...businessSignupFormikStep2, onSubmit: (values) => console.log(values) })
  const [currentStep, setCurrentStep] = useState(1);
  const handleNext = ( async () => {
    let result = await formikStep1.submitForm();
    if (result) {
      setCurrentStep(2);
    }
  }) ;

  const handleBack = () => {
    setCurrentStep(1);
  };
  const [imgProfile, setImgProfile] = useState("");
  const [formData, setFormData] = useState<formBuilder[]>([

    {
      label: "User Name",
      name: "userName",
      value: "",
      type: "TextInput",
      placeholder: "User Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Password",
      name: "password",
      value: "",
      type: "TextInput",
      placeholder: "Password",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Business Name",
      name: "name",
      value: "",
      type: "TextInput",
      placeholder: "Business Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },

    {
      label: "Email",
      name: "email",
      value: "",
      type: "TextInput",
      placeholder: "Email",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },

    {
      label: "Address",
      name: "address",
      value: "",
      type: "TextInput",
      placeholder: "Woreda",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },

    {
      label: "Business License",
      name: "businessLicense",
      value: "",
      type: "file",
      placeholder: "Business License",
      checkListValues: [],
      onChange: (value) => {

        setLoading(true)
        fileUpload(value).then((response: any) => {

          setLoading(false)
          if (response.status === 200 || response.status === 201) {
            setImgProfile(response?.data?.file);
          } else {
            alert(" Somethign went wrong");
          }

        }).catch((err) => {

        })
        console.log("value: ", value);
      },
      required: true,
    },
  ]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [socialMediaInfo, setSocialMediaInfo] = useState<formBuilder[][]>([
    [
      {
        label: "Social Media Account Name",
        value: "",
        placeholder: "Social Media Account Name",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
      {
        label: "Social Media Account Link",
        value: "",
        placeholder: "Social Media Account Link",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
      {
        label: "Social Media Subscribers Count",
        value: "58",
        placeholder: "Social Media Subscribers Count",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
    ],
  ]);
  const handleClose = () => {
    setShowErrorMessage(false);
    // Additional logic for closing the component
  };

  const navigate = useNavigate();

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [errorMsg, setErrorMsg] = useState("");
  const [updatedContent, setUpdatedContent] = useState<formBuilder[]>();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div className="createAccount-signup">
      <div className="logoContainer">
        <Link to={"/"}><img className="logo" src={logoBlack} alt="logo" /></Link>


        <div></div>
      </div>
      <div className="createAccount">
        <Loading loading={loading} setLoading={"sef"} />
        {showErrorMessage && <ErrorToast message="Some thing went wrong, please check you connnection and try again!" onClose={handleClose} />}
        <div className="loginContent">
          <div className={"formWrapper"}>
            <div className="loginTitle">
              <h1>
                SignUp |{" "}
                <a href="/creator_login" style={{ color: "#6968FF" }}>
                  Login
                </a>
              </h1>
            </div>

            <div
              className="inline-flex rounded-md shadow-sm w-9/12 mx-4 my-4"
              role="group"
            >
              <Link
                to={"/create-account"}
                type="button"
                className="px-4 py-2 w-1/2 text-sm font-medium text-gray-900 bg-transparent border  rounded-s-lg hover:bg-primary-color hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-700 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                Creator
              </Link>
              <Link
                to={"/business-create-account"}
                type="button"
                className="px-4 py-2 w-1/2 text-sm font-medium text-white bg-primary-color border  rounded-e-lg focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                Business
              </Link>
            </div>
            {errorMsg === "" ? (
              <></>
            ) : (
              <div className="errorContainer">
                <p className="font-bold text-lg">{errorMsg.toString().toLowerCase()}</p>

                <p className="font-bold">are required</p>
              </div>
            )}


            {CForm(
            formData,
            (updatedDate: formBuilder[]) => {
              setUpdatedContent(updatedDate);
            },
            start,
            end
          )}
            {/* {currentStep == 1 ?
              <>
                <FormInput type="text" label="User Name" isRequired={true}
                  placeholder="User Name" value={formikStep1.values.username}
                  errorMessage={formikStep1.errors.username} onChange={(event) => {

                    formikStep1.setFieldValue("username", event.target.value);
                  }} />
                <FormInput type="password" label="Password" isRequired={true} placeholder="Password" value={formikStep1.values.password}
                  errorMessage={formikStep1.errors.password} onChange={(event) => {
                    formikStep1.setFieldValue("password", event.target.value);
                  }} />
                <FormInput type="text" label="Business Name" isRequired={true} placeholder="Business Name" value={formikStep1.values.businessName}
                  errorMessage={formikStep1.errors.businessName} onChange={(event) => {
                    formikStep1.setFieldValue("businessName", event.target.value);
                  }} />
                <FormInput type="text" label="Email" isRequired={true} placeholder="Email" value={formikStep1.values.email}
                  errorMessage={formikStep1.errors.email} onChange={(event) => {
                    formikStep1.setFieldValue("email", event.target.value);
                  }} />

                <div className={"actions"}>
                  <button type="button" onClick={handleNext
                  } className={"roundButton bg-green-400"}>Next</button>
                </div>

              </>
              :
              <>
                <FormInput type="text" label="Address" isRequired={true} placeholder="Address" value={formikStep2.values.address}
                  errorMessage={formikStep2.errors.address} onChange={(event) => {
                    formikStep2.setFieldValue("address", event.target.value);
                  }} />
                <FormInput type="file" label="Lisense Cirtificate" isRequired={true} placeholder="Lisense Cirtificate" value={formikStep2.values.licenseCertificate}
                  errorMessage={formikStep2.errors.licenseCertificate} onChange={(event) => {
                    formikStep2.setFieldValue("licenseCertificate", event.target.value);
                  }} />
                <div className={"actions"}>
                  <button type="button" className="bg-red-400" onClick={handleBack}>Back</button>

                  <button type="submit" className={"roundButton bg-green-400"} onClick={() => formikStep2.handleSubmit()}>Submit</button>
                </div>
              </>} */}

            <div className={"actions"}>
              {/* {start === 0 ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    console.log("start: ", end - start - 1, "end: ", start - 1);
                    setStart(start - 4);
                    setEnd(end - 4);
                  }}
                  className="bg-red-400"
                >
                  Back
                </button>
              )} */}

              <button
                onClick={async () => {
                  if (end <= formData.length) {
                    var inputs = getJson(updatedContent || formData);
                    const valuesArray = Object.values(inputs);
                    const keysArray = Object.keys(inputs);

                    const extractedValues = valuesArray.slice(start, end + 1);
                    const extractedKeys = keysArray.slice(start, end + 1);
                    const emptyStringIndexes: any = [];
                    extractedValues.forEach((value, index) => {
                      if (value === "") {
                        emptyStringIndexes.push(index);
                      }
                    });

                    console.log("empty sting values: ", extractedValues);

                    if (emptyStringIndexes.length === 0) {
                      setErrorMsg("");
                      setStart(start + 4);

                      setEnd(end + 4);
                    } else {
                      var errorsMessage: string = "";
                      emptyStringIndexes.forEach((value: any, index: number) => {
                        errorsMessage += extractedKeys[value] + " ";
                      });
                      setErrorMsg(errorsMessage);
                    }
                  } else {
                    var newJson = getJson(updatedContent || formData);
                    // newJson.ind = imgProfile;
                    var content = buildCreatorApiBody(newJson);
                    setLoading(true);
                    var response: any = await createAccountApiCall(content);
                    setTimeout(() => {
                      setLoading(false);
                      if (response.status === 200 || response.status === 201) {
                        navigate("/business_login");
                      } else {
                        setLoading(false);
                        setShowErrorMessage(true);
                      }
                    }, 4000);
                  }
                }}
                className={"roundButton bg-green-400"}
              >
                {end <= formData.length ? "Next" : "Submit"}
              </button>
            </div>
          </div>
        </div>
        <div className="loginDecore">
          <img src={tools} alt="tools" />
        </div>
      </div>
    </div>
  );
}
